html {
  overflow-x: hidden;
  background: #ffffff;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
  color: #151515;
  overflow-x: hidden;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body * {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
input,
textarea,
a,
button,
label {
  border: none;
  outline: none;
}
label {
  font-weight: 500;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
}
.controls button {
  margin-bottom: 23px;
}
button,
label,
input[type="submit"] {
  cursor: pointer;
}
textarea {
  resize: vertical;
  min-height: 120px;
}
input:focus {
  outline: none;
}
input::-moz-placeholder {
  color: #8c9bb0;
}
input[type="text"]:focus,
textarea:focus {
  border-color: #7bb638;
}
b,
strong {
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
p {
  margin-bottom: 20px;
  line-height: 1.9;
  font-size: 16px;
}
.ajax_form .error {
  font-size: 13px;
}
button,
input[type="submit"],
.btn {
  display: inline-block;
  padding: 12px 22px;
  background: #f5b62f;
  border-radius: 3px;
  box-shadow: 0 10px 20px #54423154;
  text-align: center;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  color: #fff;
}
button:checked,
input[type="submit"]:checked,
.btn:checked,
button:focus,
input[type="submit"]:focus,
.btn:focus {
  color: #fff;
}
button:hover,
input[type="submit"]:hover,
.btn:hover {
  text-decoration: none;
  color: #fff;
  box-shadow: none;
}
input[type="text"],
input[type="email"],
input[type="number"],
textarea {
  display: block;
  width: 100%;
  padding: 12px 20px;
  border-radius: 3px;
  border: 1px solid #bfb8b8;
  font-size: 16px;
}
input::-webkit-input-placeholder {
  color: #000;
}
input:-ms-input-placeholder {
  color: #000;
}
input::-ms-input-placeholder {
  color: #000;
}
input::placeholder {
  color: #000;
}
button:active,
button:focus,
input:active,
input:focus {
  outline: none;
}
a:hover,
a:active,
a:focus,
a.hover {
  text-decoration: none;
  outline: none;
}
button:disabled {
  opacity: 0.7;
}
h1,
h2,
h3,
h4,
h5,
h6,
.caption {
  font-family: "Montserrat-ExtraBold", Verdana, Helvetica;
  line-height: 1.2;
  color: #544230;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat-Medium", Verdana, Helvetica;
}
/* populair coef = 1.2 for captions */
h1,
.caption {
  margin-top: 0;
  font-size: 40px;
}
h2 {
  font-size: 37px;
}
h3 {
  font-size: 35px;
}
h4 {
  font-size: 33px;
}
h5 {
  font-size: 28px;
}
h6 {
  font-size: 23px;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
ul li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 20px;
}
ul li:before {
  display: block;
  position: absolute;
  top: -4px;
  left: 0;
  font-size: 20px;
  content: "\2022";
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.row-flex:before,
.row-flex:after {
  display: flex !important;
  width: 100%;
  height: 0;
}
.middle-vr {
  display: block;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}
.red {
  border: 1px solid red;
}
.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}
.float-right {
  float: right;
}
.container:after {
  display: block;
  clear: both;
  content: " ";
}
.pd-hr-15 {
  padding-left: 15px;
  padding-right: 15px;
}
/* navigation style */
.mobile-burger {
  display: none;
  padding: 8px 5px;
  float: left;
  clear: both;
  width: 40px;
  height: 36px;
  margin: 0;
}
.mobile-burger span {
  display: block;
  margin-bottom: 6px;
  height: 2px;
  background: #ffffff;
}
.header-nav {
  position: relative;
  z-index: 1;
}
.close-nav {
  display: none;
}
/* END navigation style */

.center-text {
  text-align: center;
}
.left-text {
  text-align: left;
}
.bold {
  font-weight: 600;
}
.pd-lt-30 {
  padding-left: 30px;
}
.mr-0 {
  margin: 0;
}
.pd-0 {
  padding: 0;
}
.mr-tp-0 {
  margin-top: 0;
}
.pd-hr-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.pd-hr-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.pd-hr-0 {
  padding-left: 0;
  padding-right: 0;
}
.pd-vr-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.row-20 {
  margin-left: -20px;
  margin-right: -20px;
}
.row-10 {
  margin-left: -10px;
  margin-right: -10px;
}
.modals {
  display: none;
}
.pd-lt-80 {
  padding-left: 80px;
}
.pd-tp-75 {
  padding-top: 75px;
}
.mr-tp-30 {
  margin-top: 30px;
}
.mr-bt-70 {
  margin-bottom: 70px;
}
.pd-tp-60 {
  padding-top: 60px;
}
.pd-tp-55 {
  padding-top: 55px;
}
.pd-tp-40 {
  padding-top: 40px;
}
.pd-bt-30 {
  padding-bottom: 35px;
}
.clear-both {
  clear: both;
}
.pd-vr-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}
.pd-vr-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
.pd-vr-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.pd-bt-20 {
  padding-bottom: 20px;
}
.pd-rt-0 {
  padding-right: 0;
}
.dark {
  background: #f8f8f8;
}
.dark-header {
  position: relative;
  padding: 7px 0;
}
.dark-header a {
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  color: #000;
}
.header-nav-list {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  font-size: 16px;
}
.header-nav-list a {
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}
.header-nav-list > li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 40px;
}
.header-nav-list li,
.breadcrumbs li,
.footer-nav li,
.catalog-block li {
  margin-bottom: 0;
  padding-left: 0;
}
.header-nav-list li:before,
.breadcrumbs li:before,
.footer-nav li:before,
.catalog-block li:before,
.charact-list li:before {
  display: none;
}
.charact-list li {
  padding-left: 0;
}
.header-nav-list > li ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 250px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: #7bb638;
  transition-property: opacity;
  transition-duration: 0.2s;
  opacity: 0;
  pointer-events: none;
}
.header-nav-list li:hover > ul {
  opacity: 1;
  pointer-events: auto;
}
.header-nav-list > li ul li {
  display: block;
}
.header-nav-list > li ul li a {
  display: block;
  padding: 7px 20px;
  border-top: 1px solid #fff;
  transition-property: background;
  transition-duration: 0.2s;
}
.header-nav-list > li ul li a:hover,
.header-nav-list > li ul li.active a {
  background: #f5b62f;
}
.header-nav-list > li > a {
  position: relative;
  display: block;
  padding: 12px 0;
}
.header-nav-list > li > a:after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  content: " ";
  transition-property: height;
  transition-duration: 0.5s;
}
.header-nav-list > li a.active:after,
.header-nav-list > li > a:hover:after {
  height: 2px;
}
.header-nav-wrap {
  background: #7bb638;
  position: relative;
}
.fix_logo,
.fix_tel,
.fix_cat_btn {
  display: none;
}

.fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
.fixed .dark-header,
.fixed .header-main {
  display: none;
}
.fixed .fix_tel {
  display: block;
}
.fixed .fix_tel {
  color: #101010;
  font-size: 18px;
}
.fixed .fix_tel b {
  font-size: 22px;
}
.fixed .header-nav-wrap .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fixed .header-nav-wrap .container::before,
.fixed .header-nav-wrap .container::after,
.fixed .header-nav-wrap .row::before,
.fixed .header-nav-wrap .row::after {
  display: none;
}
.fixed__btn {
  display: none;
}
@media screen and (min-width: 992px) {
  .fixed .header-nav-wrap > .container {
    display: grid;
    grid-template-columns: 50px 170px 1fr auto 135px;
    grid-column-gap: 30px;
  }
  .fixed .header-nav-wrap {
    padding: 5px 0;
    background-color: #fff;
    box-shadow: 0.698px 9.976px 20px 0px rgba(83, 75, 75, 0.2);
  }
  .fixed .header-nav-wrap .smenu > ul.container {
    display: grid;
    align-items: flex-start;
  }
  .fixed .header-nav-wrap .row {
    position: relative;
    display: block;
    order: 10;
    grid-column: 1/-1;
    margin-top: 5px;
    margin-bottom: -5px;
    background: #7bb638;
    z-index: 2;
  }
  .fixed .middle-vr .header-search-btn {
    display: none;
  }
  .fixed .middle-vr .header-nav-container {
    width: 100%;
  }
  .fixed .header-nav-list {
    max-width: 780px;
    margin: 0 auto;
  }
  .fixed .fix_logo {
    display: block;
    width: 155px;
  }
  .fixed .header-nav-list > li {
    margin: 0 auto;
  }
  .fixed .header-nav-list > li > a {
    font-size: 18px;
  }
  .fixed .header-nav-wrap .col-sm-9 {
    width: auto;
  }
  .fixed .header-nav-wrap .col-xs-7.col-sm-3 {
    width: auto;
    padding: 0;
  }
  .header-nav-wrap.menu-opened::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 49px;
    background-color: #7bb638;
  }
  .fix-search-wrap {
    position: relative;
    width: 100%;
    max-width: 405px;
    margin: 0 auto;
  }
  .fixed .header-nav-wrap .fix-search-wrap .header-search {
    display: flex;
  }
  .fixed .fixed__btn {
    display: block;
  }
  .fixed .fixed__btn span {
    font-size: 14px;
    font-family: "Montserrat-Medium", Verdana, Helvetica;
  }
  .fixed .header_cart {
    align-self: baseline;
    margin-left: 15px;
    margin-top: 7px;
    padding-left: 45px;
    line-height: 55px;
  }
  .fixed .header_cart .num {
    left: 20px;
  }
}
@media screen and (max-width: 991px) {
  .fixed .header-nav-wrap {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .fixed .fix_tel {
    color: #fff;
  }
  .fixed .header_cart {
    width: 60px;
    height: auto;
    padding-left: 0;
    min-height: 38px;
    padding-top: 38px;
    text-align: center;
    line-height: 1;
    font-size: 13px;
    color: #fff;
    background: url(/assets/images/header_cart.svg) center 12px / 22px no-repeat;
  }
  .fixed .header_cart .num {
    right: 3px;
    left: auto;
  }
}
@media screen and (max-width: 767px) {
  .fixed .dark-header .middle-vr::before,
  .fixed .dark-header .middle-vr::after {
    display: none;
  }
  .fixed .dark-header .middle-vr {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .fixed .dark-header {
    padding: 2px 0;
  }
  .fixed .dark-header .row {
    margin: 0;
  }
  .fixed .dark-header .middle-vr div {
    width: auto;
    padding: 0;
  }
  .fixed .logo {
    margin: 0;
  }
  .fixed .dark-header .middle-vr .col-xs-12.col-sm-5,
  .fixed .dark-header .middle-vr .col-xs-12.col-sm-4 {
    display: none;
  }
  .fixed .header-contacts-item-tel {
    margin: 0;
    font-size: 18px;
    text-align: right;
  }
  .fixed .header-contacts-item-tel b {
    font-size: 22px;
  }
  .fixed .message-link {
    margin-left: 75px;
  }
  .fixed .fix_cat_btn {
    display: block;
    position: absolute;
    left: 65px;
    z-index: 10;
  }
  .fixed .header_cart {
    position: relative;
    top: auto;
    right: auto;
    margin-left: 15px;
  }
}
@media screen and (max-width: 480px) {
  .fixed .message-link {
    width: 40px;
    margin-left: 75px;
    padding: 9px;
    font-size: 0;
  }
  .fixed .message-link .glyphicon {
    font-size: 17px;
  }
  .fixed .fix_cat_btn {
    font-size: 12px;
  }
}
@media screen and (max-width: 540px) {
  .fixed .header-nav-wrap .container {
    padding-right: 5px;
  }
  .fixed .fix_tel .glyphicon {
    display: none;
  }
  .fixed .fix_tel b {
    font-size: 18px;
  }
}
@media screen and (max-width: 400px) {
  .fixed .logo {
    width: 118px;
  }
  .fixed .header_cart {
    margin-left: 0;
  }
  .fixed .fix_cat_btn {
    padding: 9px 5px;
  }
  .fixed .message-link {
    width: 35px;
    margin-left: 52px;
  }
  .fixed .fix_tel {
    font-size: 15px;
  }
  .fixed .fix_tel b {
    font-size: 16px;
  }
}
@media screen and (max-width: 370px) {
  .fixed .fix_cat_btn {
    font-size: 10px;
  }
  .fixed .fix_tel {
    font-size: 13px;
  }
  .fixed .fix_tel b {
    font-size: 14px;
  }
  .fixed .message-link {
    margin-left: 45px;
    padding: 8px;
  }
}
@media screen and (max-width: 330px) {
  .fixed .fix_tel {
    display: none;
  }
}
.main_slider .owl-nav button.owl-next,
.main_slider .owl-nav button.owl-prev {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 44px;
  margin: -22px 0 0;
  border-radius: 3px;
  border-width: 1px;
  border-color: rgb(226, 226, 226);
  border-style: solid;
  background-color: rgb(247, 247, 247);
  font-size: 0;
  background-image: url("/assets/images/rubrics_arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 2.939px 4.045px 15px 0px rgba(83, 75, 75, 0.2);
}
.main_slider .owl-nav button:hover {
  box-shadow: none;
}
.main_slider .owl-nav button.owl-next {
  right: 5px;
}
.main_slider .owl-nav button.owl-prev {
  left: 5px;
  transform: scale(-1, 1);
}
.header-main {
  position: relative;
}
.header-main .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-title {
  width: 55%;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.7);
  font-size: 40px;
  font-family: "Montserrat-ExtraBold", Verdana, Helvetica;
  color: #544230;
}
.category-item > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.category-item .catalog-item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.catalog-item {
  display: block;
  padding: 15px;
  border: 1px solid #e8e8e8;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  color: #000;
  transition-duration: 0.2s;
  transition-property: transform;
}
.main_catalog .catalog-item {
  flex-grow: 1;
  margin: 0 auto;
  padding: 15px 15px 5px;
}
.catalog-item-capt {
  display: block;
  min-height: 48px;
}
a.catalog-item:hover {
  color: #7bb638;
}
.catalog-item:hover {
  position: relative;
  transform: scale(1.04);
  background-color: #fff;
  z-index: 10;
}
.catalog-item::after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 60px);
  background-color: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.catalog-item:hover::after {
  display: block;
}
.catalog-item > * {
  position: relative;
  z-index: 1;
}
.catalog-item-more {
  display: none;
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  padding: 13px 15px;
  z-index: 10;
}
.catalog-item-more a {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  border-radius: 2px;
  background-color: rgb(123, 182, 56);
}
.catalog-item:hover .catalog-item-more {
  display: block;
}
@media screen and (max-width: 1599px) {
  .catalog-item-more a {
    font-size: 13px;
  }
}
@media screen and (max-width: 991px) {
  .catalog-item-more a {
    font-size: 12px;
  }
}
@media screen and (max-width: 680px) {
  .catalog-item .catalog-item-more {
    display: block;
    position: static;
  }
}
.m_catalog_rubrics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  padding-bottom: 40px;
  padding-top: 10px;
}
@media screen and (max-width: 900px) {
  .m_catalog_rubrics {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 679px) {
  .m_catalog_rubrics {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 413px) {
  .m_catalog_rubrics {
    grid-template-columns: 1fr;
  }
}
.m_catalog_rubrics__item {
  border-width: 1px;
  border-color: rgb(218, 218, 218);
  border-style: solid;
  background-color: rgba(255, 255, 255, 0);
}
.catalog-item-img {
  display: block;
  margin: 0;
  padding: 23px;
  text-align: center;
  background-color: #e6e6e6;
}
.m_catalog_rubrics__item a {
  color: #000000;
}
.catalog-item-capt {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  font-size: 18px;
  text-align: center;
  line-height: 1.1;
  font-family: "Montserrat-ExtraBold", Verdana, Helvetica;
}

.m_catalog_rubrics__menu_name {
  display: flex;
  justify-content: space-between;
}
.m_catalog_rubrics__menu_item:nth-child(odd) {
  background-color: rgb(245, 245, 245);
}
.m_catalog_rubrics__item_more {
  display: inline-block;
  position: relative;
  margin: 20px 0 15px;
  padding: 0 15px;
  font-size: 14px;
  color: #529411;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  cursor: pointer;
}
.m_catalog_rubrics__item_more::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 6px;
  margin-top: -3px;
  background-image: url("data:image/svg+xml,%3Csvg transform='rotate(-90)' fill='%23619a21' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 492 492' style='enable-background:new 0 0 492 492;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
}
.m_catalog_rubrics__item_more.opened::after {
  transform: rotate(180deg);
}
.m_catalog_rubrics__menu_name > a {
  display: flex;
  align-items: center;
  width: calc(100% - 27px);
  min-height: 53px;
  padding: 3px 0;
  padding-left: 15px;
  font-size: 14px;
  line-height: 1.1;
}
.m_catalog_rubrics__menu_item_arrow {
  width: 27px;
  flex-shrink: 0;
  background-image: url("data:image/svg+xml,%3Csvg transform='rotate(-90)' fill='%23619a21' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 492 492' style='enable-background:new 0 0 492 492;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  cursor: pointer;
}
.m_catalog_rubrics__menu_item_arrow.opened {
  transform: rotate(180deg);
}
.m_catalog_rubrics__menu2 {
  margin: 0;
  padding: 0 27px;
  background-color: #fff;
}
.m_catalog_rubrics__menu2 a {
  display: block;
  position: relative;
  padding: 3px 0 3px 10px;
  font-size: 13px;
}
.m_catalog_rubrics__menu2 a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #000;
}
.m_catalog_rubrics__menu_name > a:hover,
.m_catalog_rubrics__menu2 a:hover {
  color: #529411;
}
.m_catalog_rubrics__menu2 a:hover::before {
  background-color: #529411;
}
.message-link {
  display: inline-block;
}
.mr-tp-20 {
  margin-top: 20px;
}
.row-10 {
  margin-left: -10px;
  margin-right: -10px;
}
.pd-10 {
  padding: 10px;
}
.message-link {
  color: #fff;
}
.modals-inner {
  width: 340px;
}
.modals-inner-capt {
  font-size: 22px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
.modals-inner-subcapt {
  margin: 0;
  padding: 15px 0 20px;
  font-size: 15px;
  line-height: 1.2;
}
.modals-inner button[type="submit"] {
  min-width: 200px;
  font-size: 16px;
  margin-bottom: 0;
}
.modals-inner input,
.modals-inner textarea,
.modals-inner .captcha_row {
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .modals-inner textarea {
    min-height: 80px;
  }
}
@media screen and (max-width: 420px) {
  .modals-content.fancybox-content {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.modals-content {
  display: inline-block;
  background: #fff;
  padding: 44px;
  position: relative;
}
@media screen and (max-width: 480px) {
  .modals-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.header-contacts {
  position: static;
  font-size: 15px;
  text-align: center;
}
.header-contacts-item {
  display: inline-block;
  vertical-align: top;
}
.header-contacts-small {
  margin-top: 7px;
  font-size: 11px;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
  text-align: center;
}
.header-contacts-item-tel {
  line-height: 1;
  font-size: 16px;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
}
.header-contacts-item-tel a {
  white-space: nowrap;
}
.header-contacts-item-tel b {
  font-size: 22px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
@media screen and (max-width: 1100px) {
  .header-contacts-item-tel {
    text-align: left;
    font-size: 14px;
  }
  .header-contacts-item-tel b {
    font-size: 16px;
  }
}
.header-contacts-item-tel .glyphicon {
  margin-right: 13px;
}
.callback-link {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: inherit;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  border: none;
  background: none;
  box-shadow: none;
}
.callback-link:after {
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px dotted #000;
  content: " ";
}
  .callback-link:hover {
    color: #000;
  }
  .callback-link:hover .callback-link:after {
    border-top-color: #fff;
  }
.header-contacts-item-mail {
  position: relative;
  display: block;
  margin-top: 11px;
  padding-left: 28px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 249' fill='%233a3a3c'%3E%3Cpath d='M173.9,146.1A15.26,15.26,0,0,1,165,149a15.49,15.49,0,0,1-8.9-2.9L30,53.6l-13.69-10A10.25,10.25,0,0,0,0,51.83V223.75A25.25,25.25,0,0,0,25.25,249h279.5A25.25,25.25,0,0,0,330,223.75V51.83a10.25,10.25,0,0,0-16.31-8.27L300,53.6Z'/%3E%3Cpath d='M168.91,103.53l124.9-91.59A6.61,6.61,0,0,0,289.9,0H40.1a6.61,6.61,0,0,0-3.91,11.95l124.9,91.59A6.61,6.61,0,0,0,168.91,103.53Z'/%3E%3C/svg%3E");
}
.header-contacts-item-adr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header-contacts-item-adr > a {
  width: 100%;
  text-align: left;
}
.header-contacts-item-adr .header-contacts-small--left {
  margin-left: 23px;
}
.header-search {
  display: flex;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-color: rgb(245, 182, 47);
  border-style: solid;
  border-radius: 5px;
}
.header-search input[type="text"] {
  width: calc(100% - 80px);
  margin: 0;
  padding: 7px;
  padding-left: 35px;
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f5b62f' enable-background='new 0 0 515.558 515.558' height='512' viewBox='0 0 515.558 515.558' width='512'%3E%3Cpath d='m378.344 332.78c25.37-34.645 40.545-77.2 40.545-123.333 0-115.484-93.961-209.445-209.445-209.445s-209.444 93.961-209.444 209.445 93.961 209.445 209.445 209.445c46.133 0 88.692-15.177 123.337-40.547l137.212 137.212 45.564-45.564c0-.001-137.214-137.213-137.214-137.213zm-168.899 21.667c-79.958 0-145-65.042-145-145s65.042-145 145-145 145 65.042 145 145-65.043 145-145 145z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 13px center;
}
.header-search button {
  width: 80px;
  margin: 0;
  padding: 7px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  background-color: rgb(245, 182, 47);
  border-width: 1px;
  border-color: rgb(245, 182, 47);
  border-style: solid;
  border-radius: 5px 3px 3px 5px;
  box-shadow: none;
}
.fixed .header-nav-wrap .header-search {
  display: none;
}
@media (max-width: 360px) {
  .header-search input[type="text"] {
    width: calc(100% - 60px);
  }
  .header-search button {
    width: 60px;
  }
}
@media (min-width: 992px) {
  .dark-header .header-logo {
    width: 200px;
  }
  .dark-header .header-contacts {
    flex-grow: 1;
  }
  .dark-header .header-contacts .row {
    display: grid;
    grid-template-columns: auto 350px 1fr 110px;
  }
  .dark-header .header-contacts .row::before,
  .dark-header .header-contacts .row::after {
    display: none;
  }
  @media (max-width: 1140px) {
    .dark-header .header-contacts .row {
      grid-template-columns: auto 240px 1fr 110px;
    }
    .header-contacts-item-adr .header-contacts-small--left {
      margin-left: 0;
    }
  }
  .dark-header .header-contacts .row > div {
    width: 100%;
  }
  header:not(.fixed) .header-nav-wrap .header-nav-container {
    width: 100%;
  }
  header:not(.fixed) .header-nav-wrap .header-search-btn {
    display: none;
  }
  .fixed .header-nav-container {
    width: 83.33333333333334%;
  }
  .fixed .header-search-btn {
    width: 16.666666666666664%;
  }
}
@media (max-width: 991px) {
  .header-search-wrap {
    display: none;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .dark-header .header-contacts .row {
    display: grid;
    grid-template-columns: auto 1fr 110px;
  }
  .dark-header .header-contacts .row::before,
  .dark-header .header-contacts .row::after {
    display: none;
  }
  .dark-header .header-contacts .row > div {
    width: 100%;
  }
  header:not(.fixed) .message-link {
    display: none;
  }
  .header-contacts-item-adr {
    justify-content: flex-start;
  }
  .header-contacts-item-adr .header-contacts-small--left {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .header-contacts-item-tel {
    text-align: center;
  }
  .header-contacts-item-mail {
    max-width: 160px;
    margin: 10px auto 0;
  }
}
@media (max-width: 991px) {
  header:not(.fixed) .message-link {
    display: none;
  }
}
@media (max-width: 991px) {
  #search {
    position: fixed;
    left: 0px;
    top: 0px;
    max-width: 100%;
    padding: 0;
  }
  #search .modals-inner {
    max-width: 100%;
  }
  #search .modals-inner-capt {
    display: none;
  }
  #search form,
  #search .form-group {
    margin: 0;
  }
  #search form {
    display: flex;
    background-color: #f5b62f;
  }
  #search .form-group {
    width: calc(100% - 50px);
  }
  #search input[type="text"] {
    width: 100%;
    margin: 0;
    padding: 15px 5px;
    color: #fff;
    font-family: "Montserrat-Bold", Verdana, Helvetica;
    border: none;
    background-color: #f5b62f;
  }
  #search .modals-inner button[type="submit"] {
    order: -1;
    width: 50px;
    min-width: 50px;
    height: 54px;
    font-size: 0;
    border: none;
    box-shadow: none;
    background-color: #f5b62f;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' enable-background='new 0 0 515.558 515.558' height='512' viewBox='0 0 515.558 515.558' width='512'%3E%3Cpath d='m378.344 332.78c25.37-34.645 40.545-77.2 40.545-123.333 0-115.484-93.961-209.445-209.445-209.445s-209.444 93.961-209.444 209.445 93.961 209.445 209.445 209.445c46.133 0 88.692-15.177 123.337-40.547l137.212 137.212 45.564-45.564c0-.001-137.214-137.213-137.214-137.213zm-168.899 21.667c-79.958 0-145-65.042-145-145s65.042-145 145-145 145 65.042 145 145-65.043 145-145 145z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 17px center;
  }
  .modal--search .modal__close {
    position: fixed;
    right: 0;
    top: 0;
    width: 54px;
    height: 54px;
    padding: 4px;
    color: #fff;
    box-shadow: none;
  }
  #search .search_modal__result {
    padding-bottom: 0;
    padding-top: 0;
  }
  #search input::placeholder {
    color: #fff;
  }
}
.search_modal__result > p {
  text-align: left;
  padding-left: 10px;
  background-color: #fff;
}
.callback-link:hover:after {
  display: none;
}
.pd-hr-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.map {
  height: 400px;
}
.modals-inner-map {
  width: 800px;
}
.modals-inner-map .small-info {
  text-decoration: underline;
}
  .modals-inner-map .small-info:hover {
    text-decoration: none;
  }
.mr-tp-40 {
  margin-top: 40px;
}
footer {
  position: relative;
  margin-top: auto;
  padding: 40px 0 30px;
  background: #efefef;
}
footer a {
  position: relative;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  color: #000;
}
footer a:hover {
  color: #7bb638;
}
.up-link-wrap {
  position: fixed;
  right: 34px;
  bottom: 10%;
  text-align: center;
  z-index: 10;
}
.up-link {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #7bb638;
  color: #fff;
  cursor: pointer;
}
.up-link span {
  position: relative;
  top: 8px;
}
.up-link:hover,
.up-link:focus {
  background: #ccc;
  color: #fff;
}

.footer-row {
  display: flex;
  margin-bottom: 27px;
}
.col-copyright {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}
.copyrigt-block {
  margin-top: 15px;
}
.creative-box{
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  /* margin-bottom: 28px; */
  font-size: 13px;
  line-height: 20px;
}
.creative-box a {
  font-family: "Montserrat-bold", Verdana, Helvetica;
}

.copyrigt-block .copyright {
  font-family: "Montserrat-bold", Verdana, Helvetica;
  margin-top: 0px;
  font-size: 13px;
}
.copyright span {
  margin-right: 4px;
}
.creative-link {
  color: #71a932;
}
ul.footer-nav {
  padding: 0 0 5px;
  margin: 0;
  list-style-type: none;
}
.footer-nav > li {
  display: block;
  margin-bottom: 15px;
  margin-left: 5%;
  margin-right: 5%;
}
.footer-nav > li ul {
  display: none;
}
.footer-nav > li a:hover {
  text-decoration: underline;
}
footer .header-contacts-small {
  text-align: left;
}
.policy {
  font-size: 14px;
}

@media(max-width: 768px) {
  .copyrigt-block .copyright {
    margin-top: 10px;
    font-size: 12px;
  }
  .copyrigt-block .creative-box {
    font-size: 12px;
  }
}

@media(max-width: 500px) {
  .footer-row .col-xs-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .copyrigt-block .creative-box,
  .copyrigt-block .copyright {
    font-size: 10px;
  }
  .creative-box span {
    display: none;
  }
}
@media(max-width: 460px) {
  .footer-row .mr-tp-30 {
    margin-top: 70px !important;
  }
  .footer-row .header-contacts-item {
    margin: 0;
  }
  .footer-row .header-contacts-item.mr-tp-20 {
    margin-top: 14px;
  }
}


.breadcrumbs {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 14px;
}
.breadcrumbs li {
  display: inline-block;
  vertical-align: baseline;
}
.breadcrumbs li:after {
  display: inline-block;
  vertical-align: baeline;
  margin-left: 5px;
  margin-right: 3px;
  color: #343434;
  content: "/";
}
.breadcrumbs li:last-child:after {
  display: none;
}
.breadcrumbs li a {
  color: #608e2b;
}
.breadcrumbs > div {
  position: relative;
  display: inline-flex;
  margin-right: 3px;
}
.breadcrumbs_menu {
  display: none;
  position: absolute;
  top: 100%;
  left: -30px;
  width: 300px;
  padding-top: 7px;
  z-index: 100;
}
.breadcrumbs_menu ul {
  position: relative;
  margin: 0;
  padding: 10px 30px;
  list-style: none;
  background-color: #fff;
  border-top: 5px solid #7bb638;
}
.breadcrumbs_menu ul::before {
  content: "";
  position: absolute;
  left: 24px;
  top: -20px;
  border: 8px solid transparent;
  border-top: 8px solid #7bb638;
  transform: rotate(180deg);
  transform-origin: center;
}
@media (max-width: 767px) {
  .breadcrumbs_menu {
    left: -10px;
    width: 200px;
  }
  .breadcrumbs_menu ul {
    padding: 10px;
  }
  .breadcrumbs_menu ul::before {
    left: 5px;
  }
}
.breadcrumbs_menu li {
  display: block;
}
.breadcrumbs_menu li::after {
  display: none;
}
.breadcrumbs_menu li a {
  display: block;
  padding: 7px 0;
  font-size: 11px;
  line-height: 1.1;
  color: #000000;
}
.breadcrumbs_menu li:hover > a {
  color: #608e2b;
  text-decoration: none;
}
.breadcrumbs_menu__arrow {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 15px;
  line-height: 0;
  font-size: 0;
}
.breadcrumbs_menu__arrow::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border: 4px solid transparent;
  border-top: 6px solid #6f8853;
  transform: rotate(-90deg);
  transform-origin: center;
}
@media screen and (min-width: 681px) {
  .breadcrumbs > div:hover .breadcrumbs_menu,
  .breadcrumbs > li:hover .breadcrumbs_menu {
    display: block;
  }
}

.breadcrumbs > div:hover .breadcrumbs_menu__arrow::after,
.breadcrumbs > li:hover .breadcrumbs_menu__arrow::after {
  bottom: -2px;
  transform: none;
}
.catalog_wrapper {
  position: relative;
}
.catalog_aside {
  width: 285px;
  position: static;
}
.catalog_content {
  width: calc(100% - 285px);
}
@media screen and (max-width: 991px) {
  .catalog_aside,
  .catalog_content {
    width: 100%;
  }
}
.catalog-block {
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: #fff;
  border: 1px solid #e2e2e2;
}
.catalog-block a {
  display: block;
  padding: 7px 15px;
  padding-right: 28px;
  border-bottom: 1px solid #e2e2e2;
  color: #000;
}
.catalog-block .submenu > a {
  background-repeat: no-repeat;
  background-position: calc(100% - 9px) center;
  background-image: url("/assets/images/aside_arrow.png");
}
.catalog-block li:last-child a {
  border: none;
}

.catalog-block > li {
  display: block;
}
.catalog-block li:hover > a,
.catalog-block li.active > a {
  background-color: #7bb638;
  color: #fff;
}
.catalog-block .submenu:hover > a,
.catalog-block .submenu.active > a {
  background-image: url("/assets/images/aside_arrow_act.png");
}
@media screen and (max-width: 991px) {
  .catalog-block .submenu > a.m-open {
    background-color: #7bb638;
    color: #fff;
    background-image: url("/assets/images/aside_arrow_act_down.png");
  }
  .catalog-block li {
    position: static;
  }
}
@media screen and (min-width: 992px) {
  .catalog-block ul .submenu > a,
  .catalog-block ul .submenu:hover > a,
  .catalog-block ul .submenu.active > a {
    background-image: none;
  }
}
.catalog-block > li > ul {
  display: none;
  position: absolute;
  left: 255px;
  top: -60px;
  width: 837px;
  /* width: 75%; */
  /* min-height: 555px; */
  padding: 0;
  border-radius: 10px;
  padding-left: 35px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.85);
  list-style-type: none;
  font-size: 15px;
  z-index: 2;
}
@media screen and (max-width: 991px) {
  .catalog-block > li > ul {
    position: static;
    width: 100%;
    min-height: 0;
    height: auto;
    padding: 0;
  }
  .catalog-block > li > ul > li > ul {
    display: none;
  }
  .catalog-block > li ul.onhover {
    display: block;
  }
}
/* 	@media screen and (max-width: 1199px) {
		.catalog-block > li > ul  {
			top: -31px;
		}
	} */
.catalog-block > li > ul.onhover {
  display: block;
  column-count: 3;
}
@media screen and (max-width: 991px) {
  .catalog-block > li > ul.onhover {
    column-count: 1;
  }
}
.catalog-block li ul a {
  padding: 7px 10px;
  background-color: #fff;
}
.catalog-block > li > ul > li {
  break-inside: avoid-column;
  margin-bottom: 15px;
  /* for Firefox */
  display: inline-grid;
  page-break-inside: avoid;
}
/* for Chrome and Edge */
@supports (break-inside: avoid-column) {
  .catalog-block > li > ul > li {
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .catalog-block > li > ul > li {
    margin-bottom: 0;
  }
}
.catalog-block > li > ul > li > ul {
  border-radius: 2px;
  border: 1px solid #e2e2e2;
}
.catalog-block > li > ul > li li:nth-child(odd) a {
  background-color: #e7e2e2;
}
@media screen and (min-width: 992px) {
  .catalog-block > li > ul > li > a {
    position: relative;
    border-radius: 2px;
    background-color: #7bb638;
    color: #fff;
    font-family: "Montserrat-Medium", Verdana, Helvetica;
  }
  .catalog-block > li > ul > li.submenu > a::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: calc(50% - 6px);
    border: 8px solid transparent;
    border-top: 7px solid #7bb638;
  }
}
@media screen and (max-width: 1366px) {
  .catalog-block li ul a {
    font-size: 14px;
  }
}
@media screen and (max-width: 1199px) {
  .catalog-block li ul a {
    padding: 6px 12px;
    font-size: 13px;
  }
}

.catalog-block li.active > a {
  background-color: #7bb638;
  border: none;
}
.catalog-block > li.active {
  border-bottom: 1px solid #7bb638;
}
.catalog-block > li > ul > li li:nth-child(odd) a:hover,
.catalog-block > li > ul a:hover {
  background-color: #f5b62f;
  color: #fff;
}
.catalog-block > li > ul ul li.active > a {
  background-color: #f5b62f;
}

.catalog-block > li > ul ul ul {
  display: none;
  padding: 3px 27px 8px;
}
.catalog-block > li > ul ul ul a {
  padding: 3px 0;
  font-size: 13px;
  line-height: 1.1;
}
.catalog-block > li > ul ul ul li:nth-child(odd) a {
  background-color: #fff;
}
@media screen and (min-width: 992px) {
  .catalog-block > li > ul > li > ul > li {
    padding: 7px 10px;
    background-color: #fff;
  }
  .catalog-block > li > ul > li > ul > li:nth-child(odd) {
    background-color: #e7e2e2;
  }
  .catalog-block > li > ul > li > ul > li:nth-child(odd) a,
  .catalog-block > li > ul > li > ul > li > a {
    padding: 0;
    background-color: inherit;
  }
  .catalog-block > li > ul > li > ul > li:hover,
  .catalog-block > li > ul > li > ul > li.active {
    background-color: #f5b62f;
  }
  .catalog-block > li > ul > li > ul > li:hover > a,
  .catalog-block > li > ul > li > ul > li.active > a {
    background-color: inherit;
  }
  .catalog-block > li > ul > li > ul > li.submenu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .catalog-block > li > ul > li > ul > li.submenu > a {
    display: inline-block;
  }
  .catalog-block > li > ul > li > ul > li.submenu > .level4arrow {
    display: inline-block;
    width: 20px;
    height: 10px;
    background-position: center right;
    background-repeat: no-repeat;
    background-image: url("/assets/images/level4arrow.png");
  }
  .catalog-block > li > ul > li > ul > li.submenu > ul {
    width: 100%;
  }
  .catalog-block > li > ul > li > ul > li.submenu:hover {
    background-color: #fff;
    color: #4c8700;
  }
  .catalog-block > li > ul > li > ul > li.submenu:hover > a {
    color: #4c8700;
  }
  .catalog-block > li > ul > li > ul > li.submenu:hover > ul {
    display: block;
    background-color: #fff;
  }
  .catalog-block > li > ul > li > ul > li.submenu a:hover,
  .catalog-block > li > ul > li > ul > li.submenu .active > a {
    color: #4c8700;
    background-color: #fff;
  }
}

/* .catalog-block > li.active ul a:hover,
.catalog-block > li.active ul li.active a {
	text-decoration: underline;
	color: #757575;
} */
/* .catalog-block > li.active ul li {
	position: relative;
}
.catalog-block > li.active ul li:before {
	display: block;
	position: absolute;
	top: 40%;
	left: 0;
	width: 3px;
	height: 3px;
	border-radius: 3px;
	background: #f5b62f;
	content: " ";
} */
.catalog-capt {
  padding: 7px 15px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  font-size: 14px;
  background: #f5b62f;
  border-radius: 3px 3px 0 0;
}
.product-inner h1 {
  margin-top: 0;
  font-size: 34px;
}
.d-inline {
  display: inline-block;
  vertical-align: baseline;
}
.item_actions {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 3px;
  align-items: flex-start;
}
  @media (min-width: 1200px) {
    .item_actions.mr-tp-20 {
      margin-top: 25px;
    }
  }
.item_actions .btn {
  margin-right: auto;
}
.item_actions .btn--one {
  grid-column: 2/3;
}
.item_actions .quant_btns {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item_actions .quant_btns .good-btn {
  width: 22px;
  height: 31px;
  border-width: 1px;
  border-color: rgb(241, 241, 241);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  line-height: 27px;
  font-size: 14px;
}
.item_actions .quant_btns .minus {
  border-radius: 3px 0 0 3px;
}
.item_actions .quant_btns .plus {
  border-radius: 0 3px 3px 0;
}
.item_actions .quant_btns input {
  width: 53px;
  height: 31px;
  margin: 0;
  padding: 0 5px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  border-width: 1px;
  border-color: rgb(241, 241, 241);
  border-style: solid;
  border-radius: 0;
}
.quant_btns__summ {
  width: 100%;
  margin-top: 7px;
  font-size: 12px;
}
.quant_btns__summ b {
  font-size: 14px;
  font-weight: 500;
}
.price-val {
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  font-size: 35px;
}
.price-currency {
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  font-size: 20px;
}
.product-inner .btn--one {
  margin-left: 20px;
  padding-left: 45px;
  padding-right: 22px;
  background-color: #7bb638;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:%23fff;%7D.cls-3%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle/%3E%3Cg id='Click'%3E%3Cpath class='cls-1' d='M27,28a1,1,0,0,1-.71-.29l-8-8a1,1,0,0,1,1.42-1.42l8,8a1,1,0,0,1,0,1.42A1,1,0,0,1,27,28Z'/%3E%3Cpath class='cls-2' d='M12,9a1,1,0,0,1-1-1V5a1,1,0,0,1,2,0V8A1,1,0,0,1,12,9Z'/%3E%3Cpath class='cls-2' d='M8.88,9.88a1,1,0,0,1-.71-.29L6.05,7.46A1,1,0,0,1,7.46,6.05L9.59,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,8.88,9.88Z'/%3E%3Cpath class='cls-2' d='M8,13H5a1,1,0,0,1,0-2H8a1,1,0,0,1,0,2Z'/%3E%3Cpath class='cls-2' d='M6.76,18.24A1,1,0,0,1,6.05,18a1,1,0,0,1,0-1.41l2.12-2.13a1,1,0,0,1,1.42,1.42L7.46,18A1,1,0,0,1,6.76,18.24Z'/%3E%3Cpath class='cls-2' d='M15.12,9.88a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l2.13-2.12A1,1,0,0,1,18,7.46L15.83,9.59A1,1,0,0,1,15.12,9.88Z'/%3E%3Cpath class='cls-3' d='M26.34,16.06l-14-5a1,1,0,0,0-1.28,1.28l5,14A1,1,0,0,0,17,27h0a1,1,0,0,0,.93-.73l1.85-6.46L26.27,18a1,1,0,0,0,.07-1.9Z'/%3E%3C/g%3E%3C/svg%3E");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 25px;
}
.product-inner .btn--cart {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(245, 182, 47);
  border-radius: 3px;
}
.product-inner .btn--cart .glyphicon {
  position: relative;
  width: 17px;
  height: 14px;
  margin-right: 7px;
}
.product-inner .btn--cart .glyphicon::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 17px;
  height: 17px;
  // background-image: url("/assets/images/cart_shopping_ic_icon.svg");
  background-size: 20px;
  filter: invert(89%) sepia(87%) saturate(8%) hue-rotate(265deg)
    brightness(111%) contrast(97%);
}
.product-inner .inCart {
  color: rgb(245, 182, 47);
  background-color: #fff;
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.25);
}
.product-inner .inCart .glyphicon::before {
  filter: invert(92%) sepia(21%) saturate(3851%) hue-rotate(331deg)
    brightness(97%) contrast(99%);
}
.item_actions .btn--cart {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 25px;
  font-size: 14px;
}
.item_actions .btn--one {
  margin-left: 0;
  padding-top: 12px;
  padding-bottom: 14px;
  padding-left: 47px;
  padding-right: 20px;
  font-size: 14px;
}
  @media (max-width: 380px) {
    .item_actions {
      grid-template-columns: 110px 1fr;
      grid-column-gap: 5px;
    }
    .item_actions .btn--cart {
      padding-left: 12px;
      padding-right: 15px;
      font-size: 12px;
    }
    .item_actions .btn--one {
      padding-left: 30px;
      padding-right: 12px;
      font-size: 12px;
      background-position: 3px center;
    }
  }
.product-desc-nav:after {
  display: block;
  clear: both;
  content: " ";
}
.product-desc-nav-item {
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  left: -2px;
  padding: 12px 25px;
  width: 190px;
  border: 2px solid #f2f2f2;
  background-color: #f9f9fa;
  box-shadow: none;
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  color: #151515;
}
.product-desc-nav-item:hover,
.product-desc-nav-item:focus {
  color: #151515;
}
.product-desc-nav-item.active {
  background: #fff;
  border-bottom-color: #fff;
  border-top-color: #7bb638;
}
.product-desc-nav-item:hover {
  border-top-color: #7bb638;
}
.product-desc-item {
  padding-top: 20px;
  clear: both;
}
.choose_product {
  font-size: 14px;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  line-height: 1.2;
}
.product-min-desc {
  font-size: 14px;
}
.charact-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 14px;
}
.charact-list li {
  margin-bottom: 15px;
}
.charact-list-title {
  position: relative;
  padding-right: 0.33em;
  background: white;
  overflow: hidden;
  width: 55%;
  color: #727272;
}
.charact-list-value {
  color: #727272;
  padding-left: 0.33em;
  width: 45%;
  background: white;
  position: static;
}
.dotted-el {
  display: inline-block;
  line-height: 0.5;
}
.dotted-el:after {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
}
.category-item .catalog-item-capt {
  display: block;
  min-height: 48px;
  margin-bottom: 10px;
  font-size: 15px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  color: #000;
}
.good-item .catalog-item-capt {
  min-height: inherit;
}
.good-article {
  margin-bottom: 10px;
  margin-top: 7px;
  font-size: 13px;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
}
.good-item_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.good-item_price .price-val {
  max-width: calc(100% - 100px);
  line-height: 1.3;
}
.good-item_price span:first-child {
  display: block;
  text-align: left;
}
.good-item_btns {
  display: flex;
  justify-content: space-between;
  column-gap: 3px;
}
.good-item_price .btn {
  width: 40px;
  height: 36px;
  font-size: 14px;
  text-align: center;
}
.good-item_price .btn:focus {
  outline: none;
  box-shadow: 2.084px 2.158px 10px 0px rgba(0, 0, 0, 0.35);
}
.good-item_price .btn .glyphicon:empty {
  margin: 0 auto;
}
.good-item_price .btn--one {
  position: relative;
  padding: 0;
  background-color: #7bb638;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:%23fff;%7D.cls-3%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle/%3E%3Cg id='Click'%3E%3Cpath class='cls-1' d='M27,28a1,1,0,0,1-.71-.29l-8-8a1,1,0,0,1,1.42-1.42l8,8a1,1,0,0,1,0,1.42A1,1,0,0,1,27,28Z'/%3E%3Cpath class='cls-2' d='M12,9a1,1,0,0,1-1-1V5a1,1,0,0,1,2,0V8A1,1,0,0,1,12,9Z'/%3E%3Cpath class='cls-2' d='M8.88,9.88a1,1,0,0,1-.71-.29L6.05,7.46A1,1,0,0,1,7.46,6.05L9.59,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,8.88,9.88Z'/%3E%3Cpath class='cls-2' d='M8,13H5a1,1,0,0,1,0-2H8a1,1,0,0,1,0,2Z'/%3E%3Cpath class='cls-2' d='M6.76,18.24A1,1,0,0,1,6.05,18a1,1,0,0,1,0-1.41l2.12-2.13a1,1,0,0,1,1.42,1.42L7.46,18A1,1,0,0,1,6.76,18.24Z'/%3E%3Cpath class='cls-2' d='M15.12,9.88a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l2.13-2.12A1,1,0,0,1,18,7.46L15.83,9.59A1,1,0,0,1,15.12,9.88Z'/%3E%3Cpath class='cls-3' d='M26.34,16.06l-14-5a1,1,0,0,0-1.28,1.28l5,14A1,1,0,0,0,17,27h0a1,1,0,0,0,.93-.73l1.85-6.46L26.27,18a1,1,0,0,0,.07-1.9Z'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px;
}
.good-item_price .category-btn {
  position: relative;
}
.good-item_price .category-btn.inCart {
  background-color: #f4b66a;
}
.good-item_price .btn--one span,
.good-item_price .category-btn .text {
  display: none;
}
.good-item_price .btn--one:hover span,
.good-item_price .category-btn:hover .text {
  display: block;
  position: absolute;
  bottom: 27px;
  left: calc(50% - 59px);
  width: 125px;
  padding: 8px 5px;
  font-size: 11px;
  text-align: center;
  color: #544230;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  border-radius: 2px;
  background-color: rgb(255, 254, 255);
  box-shadow: 2.084px 2.158px 10px 0px rgba(0, 0, 0, 0.35);
  z-index: 5;
}

.good-item .oneclick_catalog {
  margin: auto auto 0;
  padding-left: 45px;
  padding-right: 22px;
  background-color: #7bb638;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:%23fff;%7D.cls-3%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle/%3E%3Cg id='Click'%3E%3Cpath class='cls-1' d='M27,28a1,1,0,0,1-.71-.29l-8-8a1,1,0,0,1,1.42-1.42l8,8a1,1,0,0,1,0,1.42A1,1,0,0,1,27,28Z'/%3E%3Cpath class='cls-2' d='M12,9a1,1,0,0,1-1-1V5a1,1,0,0,1,2,0V8A1,1,0,0,1,12,9Z'/%3E%3Cpath class='cls-2' d='M8.88,9.88a1,1,0,0,1-.71-.29L6.05,7.46A1,1,0,0,1,7.46,6.05L9.59,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,8.88,9.88Z'/%3E%3Cpath class='cls-2' d='M8,13H5a1,1,0,0,1,0-2H8a1,1,0,0,1,0,2Z'/%3E%3Cpath class='cls-2' d='M6.76,18.24A1,1,0,0,1,6.05,18a1,1,0,0,1,0-1.41l2.12-2.13a1,1,0,0,1,1.42,1.42L7.46,18A1,1,0,0,1,6.76,18.24Z'/%3E%3Cpath class='cls-2' d='M15.12,9.88a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l2.13-2.12A1,1,0,0,1,18,7.46L15.83,9.59A1,1,0,0,1,15.12,9.88Z'/%3E%3Cpath class='cls-3' d='M26.34,16.06l-14-5a1,1,0,0,0-1.28,1.28l5,14A1,1,0,0,0,17,27h0a1,1,0,0,0,.93-.73l1.85-6.46L26.27,18a1,1,0,0,0,.07-1.9Z'/%3E%3C/g%3E%3C/svg%3E");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 25px;
}
.good-item_noprice {
  display: flex;
  justify-content: center;
  margin-top: auto;
}
.good-item_noprice .category-btn {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.good-item_noprice .oneclick_catalog {
  margin: 0;
  padding-left: 35px;
  padding-right: 15px;
  background-position: 10px;
}
.itemslider .slider-nav {
  margin: 20px auto 0;
}
.itemslider .slider-nav .wrap-img {
  padding: 0 8px;
  display: block;
  max-width: 100%;
}
.itemslider .slider-nav .wrap-img img,
.itemslider .slider-for .slide-item {
  border: 2px solid rgb(228, 228, 228);
  text-align: center;
}
.itemslider .slider-nav .slick-current {
  outline: none;
}
.itemslider .slider-nav .swiper-slide-thumb-active img {
  border: 2px solid #f5b62f;
}
.itemslider .slider-nav .slick-track {
  margin-left: 0;
}
.slider-nav__wrapper {
  position: relative;
}
.itemslider .swiper-button-prev,
.itemslider .swiper-button-next {
  position: absolute;
  top: calc(50% - 18px);
  display: block;
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 17px;
  height: 36px;
  padding: 0;
  border-radius: 3px;
  background-color: rgb(228, 228, 228);
  background-image: url("/assets/images/aside_arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
}
.itemslider .swiper-button-prev:hover,
.itemslider .swiper-button-next:hover {
  background-color: #f5b62f;
}
.itemslider .swiper-button-prev {
  left: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.itemslider .swiper-button-next {
  right: 0;
}

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.pagination li {
  padding-left: 0;
}
.pagination li:before {
  display: none;
}
.pagination li a,
.pagination li span {
  color: #544231;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .pagination > li > a,
  .pagination > li > span {
    padding: 6px;
  }
}
.pagination li.active > a,
.pagination li.active > span {
  color: #fff;
  background: #7bb637;
  border-color: #7bb637;
}
.good-article-value {
  font-family: "Montserrat-Medium", Verdana, Helvetica;
}
.category-item .price-val {
  display: inline-block;
  max-width: calc(100% - 40px);
  font-size: 23px;
}
.category-item .price-currency {
  font-size: 14px;
}
.mr-tp-10 {
  margin-top: 10px;
}
.category-btn {
  padding: 7px 12px;
}
.d-inline:after {
  display: block;
  clear: both;
  content: " ";
}
.float-right {
  float: right;
}
.category-btn {
  display: inline-block;
}
.sale_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.sale_item:last-child {
  margin-bottom: 95px;
}
.sale_item__img {
  width: 350px;
}
.sale_item__text {
  width: calc(100% - 405px);
  align-self: center;
}
@media screen and (max-width: 800px) {
  .sale_item:last-child {
    margin-bottom: 65px;
  }
  .sale_item__img {
    width: 250px;
  }
  .sale_item__text {
    width: calc(100% - 275px);
    align-self: center;
  }
}
@media screen and (max-width: 500px) {
  .sale_item {
    margin-bottom: 20px;
  }
  .sale_item:last-child {
    margin-bottom: 45px;
  }
  .sale_item__img {
    width: 180px;
  }
  .sale_item__text {
    width: calc(100% - 200px);
    align-self: center;
  }
}
@media screen and (max-width: 399px) {
  .sale_item__img {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
  .sale_item__text {
    width: 100%;
    text-align: center;
  }
}
.sale-img {
  border-radius: 5px;
}
.sale-capt {
  margin: 0;
  padding: 0;
  font-family: "Montserrat-ExtraBold", Verdana, Helvetica;
  font-size: 30px;
  line-height: 1.3;
  color: #7bb638;
}
.sale-capt span {
  text-transform: uppercase;
}
.sale-capt b {
  font-size: 48px;
}
@media screen and (min-width: 801px) {
  .sale-desc {
    margin-bottom: 30px;
  }
}
.sale-desc p {
  max-width: 400px;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 1.2;
  color: #544230;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
}
.mr-bt-10 {
  margin-bottom: 10px;
}
.contacts-item {
  margin-bottom: 15px;
}
.contacts-item a {
  text-decoration: underline;
  color: #627151;
}
.contacts-item a:hover {
  text-decoration: none;
  color: #627151;
}
.contacts-item-inner {
  font-family: "Montserrat-Medium", Verdana, Helvetica;
}
.menu-cat-wrap,
.close-nav-catalog {
  display: none;
}
.mr-bt-20 {
  margin-bottom: 20px;
}
.main-content {
  min-height: 46vh;
}
.main-content-inners {
  display: block;
  width: 100%;
}
.policy-form {
  font-size: 13px;
  position: relative;
}
.policy-check input {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  opacity: 0;
  margin: 0;
}
.policy-check > span {
  display: block;
  padding-left: 38px;
  line-height: 1.25;
}
.policy-check > span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 23px;
  height: 23px;
  border: 1px solid #bfb8b8;
  border-radius: 5px;
}
.policy-check input:checked ~ span::before {
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg fill='none' height='24' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E")
    center center / 80% no-repeat;
}
.error-el {
  position: relative;
  z-index: 2;
  margin-top: 3px;
  font-size: 12px;
  color: red;
}
.succes-modal {
  display: none;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.succes-modal.active {
  display: block;
}
.succes-modal-inner {
  position: relative;
  z-index: 1;
  top: 30%;
  display: inline-block;
  vertical-align: middle;
  padding: 30px 50px;
  background: #7bb637;
  border-radius: 10px;
  color: #fff;
}
.succes-modal a {
  color: #fff;
}
.succes-modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
}
.btn-up {
  position: fixed;
}
/*
 * generated by less
 */
@media screen and (max-width: 1599px) {
  body,
  p,
  .header-nav-list,
  .header-contacts {
    font-size: 15px;
  }
  button,
  input[type="submit"],
  .btn {
    padding: 11px 20px;
    font-size: 13px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    padding: 11px 18px;
  }
  input,
  textarea,
  button,
  label {
    margin-bottom: 18px;
  }
  p {
    margin-bottom: 18px;
  }
  h1,
  .caption {
    font-size: 36px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 30px;
  }
  h5 {
    font-size: 26px;
  }
  h6 {
    font-size: 21px;
  }
  ul li:before {
    font-size: 18px;
  }
  .pd-lt-30 {
    padding-left: 27px;
  }
  .pd-hr-20 {
    padding-left: 18px;
    padding-right: 18px;
  }
  .pd-vr-25 {
    padding-top: 23px;
    padding-bottom: 23px;
  }
  .pd-lt-80 {
    padding-left: 72px;
  }
  .pd-tp-75 {
    padding-top: 68px;
  }
  .mr-tp-30 {
    margin-top: 27px;
  }
  .mr-bt-70 {
    margin-bottom: 63px;
  }
  .pd-tp-60 {
    padding-top: 54px;
  }
  .pd-tp-55 {
    padding-top: 50px;
  }
  .pd-tp-40 {
    padding-top: 36px;
  }
  .pd-bt-30 {
    padding-bottom: 32px;
  }
  .pd-vr-75 {
    padding-top: 68px;
    padding-bottom: 68px;
  }
  .pd-vr-90 {
    padding-top: 81px;
    padding-bottom: 81px;
  }
  .pd-vr-60 {
    padding-top: 54px;
    padding-bottom: 54px;
  }
  .pd-bt-20 {
    padding-bottom: 18px;
  }
  .header-nav-list > li {
    margin-left: 36px;
  }
  .header-nav-list > li ul li a {
    padding: 7px 18px;
  }
  .header-nav-list > li > a {
    padding: 11px 0;
  }
  .header-title {
    margin-top: 36px;
    margin-bottom: 36px;
    padding: 36px;
    font-size: 36px;
  }
  .catalog-item {
    padding: 14px;
  }
  .mr-tp-20 {
    margin-top: 18px;
  }
  .modals-inner-capt {
    font-size: 22px;
  }
  .header-contacts-small {
    font-size: 12px;
  }
  .pd-hr-40 {
    padding-left: 36px;
    padding-right: 36px;
  }
  .map {
    height: 360px;
  }
  .mr-tp-40 {
    margin-top: 36px;
  }
  footer {
    padding: 36px 0 27px;
  }
  .copyright {
    margin-top: 18px;
    font-size: 13px;
  }
  /* .footer-nav {
    margin: 0 0 27px;
  } */
  .footer-nav > li {
    margin-bottom: 14px;
  }
  .col-copyright {
    padding-bottom: 17px;
  }
  .policy,
  .breadcrumbs {
    font-size: 13px;
  }
  .price-val {
    font-size: 32px;
  }
  .price-currency {
    font-size: 18px;
  }
  .product-desc-nav-item {
    padding: 11px 23px;
    width: 171px;
    font-size: 13px;
  }
  .product-desc-item {
    padding-top: 18px;
  }
  .choose_product,
  .product-min-desc,
  .charact-list {
    font-size: 13px;
  }
  .charact-list li {
    margin-bottom: 14px;
  }
  .category-item .catalog-item-capt,
  .category-item .price-currency {
    font-size: 13px;
  }
  .category-item .price-val {
    font-size: 21px;
  }
  .category-btn {
    padding: 7px 11px;
  }
  .sale-capt {
    font-size: 24px;
  }
  .contacts-item {
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 1366px) {
  body,
  p,
  .header-nav-list,
  .header-contacts {
    font-size: 14px;
  }
  button,
  input[type="submit"],
  .btn {
    padding: 11px 19px;
    font-size: 12px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    padding: 11px 17px;
  }
  input,
  textarea,
  button,
  label {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 17px;
  }
  h1,
  .caption {
    font-size: 34px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 29px;
  }
  h5 {
    font-size: 24px;
  }
  h6 {
    font-size: 20px;
  }
  ul li:before {
    font-size: 17px;
  }
  .pd-lt-30 {
    padding-left: 26px;
  }
  .pd-hr-20 {
    padding-left: 17px;
    padding-right: 17px;
  }
  .pd-vr-25 {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .pd-lt-80 {
    padding-left: 68px;
  }
  .pd-tp-75 {
    padding-top: 64px;
  }
  .mr-tp-30 {
    margin-top: 26px;
  }
  .mr-bt-70 {
    margin-bottom: 60px;
  }
  .pd-tp-60 {
    padding-top: 51px;
  }
  .pd-tp-55 {
    padding-top: 47px;
  }
  .pd-tp-40 {
    padding-top: 34px;
  }
  .pd-bt-30 {
    padding-bottom: 30px;
  }
  .pd-vr-75 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pd-vr-90 {
    padding-top: 77px;
    padding-bottom: 77px;
  }
  .pd-vr-60 {
    padding-top: 51px;
    padding-bottom: 51px;
  }
  .pd-bt-20 {
    padding-bottom: 17px;
  }
  .header-nav-list > li {
    margin-right: 34px;
  }
  .header-nav-list > li ul li a {
    padding: 6px 17px;
  }
  .header-nav-list > li > a {
    padding: 11px 0;
  }
  .header-title {
    margin-top: 34px;
    margin-bottom: 34px;
    padding: 34px;
    font-size: 34px;
  }
  .catalog-item {
    padding: 13px;
  }
  .mr-tp-20 {
    margin-top: 17px;
  }
  .modals-inner-capt {
    font-size: 19px;
  }
  .header-contacts-small {
    font-size: 12px;
  }
  .pd-hr-40 {
    padding-left: 34px;
    padding-right: 34px;
  }
  .map {
    height: 340px;
  }
  .mr-tp-40 {
    margin-top: 34px;
  }
  footer {
    padding: 34px 0 26px;
  }
  .copyright {
    margin-top: 17px;
    font-size: 12px;
  }
  .footer-nav {
    margin: 0 0 26px;
  }
  .footer-nav > li {
    margin-bottom: 13px;
  }
  .policy,
  .breadcrumbs {
    font-size: 12px;
  }
  .price-val {
    font-size: 30px;
  }
  .price-currency {
    font-size: 17px;
  }
  .product-desc-nav-item {
    padding: 11px 22px;
    width: 162px;
    font-size: 12px;
  }
  .product-desc-item {
    padding-top: 17px;
  }
  .choose_product,
  .product-min-desc,
  .charact-list {
    font-size: 12px;
  }
  .charact-list li {
    margin-bottom: 13px;
  }
  .category-item .catalog-item-capt,
  .category-item .price-currency {
    font-size: 12px;
  }
  .category-item .price-val {
    font-size: 20px;
  }
  .category-btn {
    padding: 6px 11px;
  }
  .contacts-item {
    margin-bottom: 13px;
  }
  .product-inner h1 {
    font-size: 31px;
  }
}
@media screen and (max-width: 1199px) {
  body,
  p,
  .header-nav-list,
  .header-contacts {
    font-size: 13px;
  }
  button,
  input[type="submit"],
  .btn {
    padding: 10px 18px;
    font-size: 12px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    padding: 10px 16px;
  }
  input,
  textarea,
  button,
  label {
    margin-bottom: 19px;
  }
  p {
    margin-bottom: 16px;
  }
  h1,
  .caption {
    font-size: 32px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 27px;
  }
  h5 {
    font-size: 23px;
  }
  h6 {
    font-size: 19px;
  }
  ul li:before {
    font-size: 16px;
  }
  .pd-lt-30 {
    padding-left: 24px;
  }
  .pd-hr-20 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .pd-vr-25 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pd-lt-80 {
    padding-left: 64px;
  }
  .pd-tp-75 {
    padding-top: 60px;
  }
  .mr-tp-30 {
    margin-top: 24px;
  }
  .mr-bt-70 {
    margin-bottom: 56px;
  }
  .pd-tp-60 {
    padding-top: 48px;
  }
  .pd-tp-55 {
    padding-top: 44px;
  }
  .pd-tp-40 {
    padding-top: 32px;
  }
  .pd-bt-30 {
    padding-bottom: 28px;
  }
  .pd-vr-75 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pd-vr-90 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .pd-vr-60 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pd-bt-20 {
    padding-bottom: 16px;
  }
  .header-nav-list > li {
    margin-right: 32px;
  }
  .header-nav-list > li ul li a {
    padding: 6px 16px;
  }
  .header-nav-list > li > a {
    padding: 10px 0;
  }
  .header-title {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 32px;
    font-size: 32px;
  }
  .catalog-item {
    padding: 12px;
  }
  .mr-tp-20 {
    margin-top: 16px;
  }
  .modals-inner-capt {
    font-size: 18px;
  }
  .header-contacts-small {
    font-size: 11px;
  }
  .pd-hr-40 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .map {
    height: 320px;
  }
  .mr-tp-40 {
    margin-top: 32px;
  }
  footer {
    padding: 32px 0 24px;
  }
  .copyright {
    margin-top: 16px;
    font-size: 12px;
  }
  .footer-nav {
    margin: 0 0 24px;
  }
  /* .footer-nav > li {
    margin-bottom: 12px;
  } */
  .policy,
  .breadcrumbs,
  .catalog-capt {
    font-size: 12px;
  }
  .catalog-block a {
    padding: 6px 12px;
    padding-right: 28px;
  }
  .catalog-capt {
    padding: 6px 12px;
  }
  .price-val {
    font-size: 28px;
  }
  .price-currency {
    font-size: 16px;
  }
  .product-desc-nav-item {
    padding: 10px 20px;
    width: 152px;
    font-size: 12px;
  }
  .product-desc-item {
    padding-top: 16px;
  }
  .choose_product,
  .product-min-desc,
  .charact-list {
    font-size: 12px;
  }
  .charact-list li {
    margin-bottom: 12px;
  }
  .category-item .catalog-item-capt,
  .category-item .price-currency {
    font-size: 12px;
  }
  .category-item .price-val {
    font-size: 19px;
  }
  .category-btn {
    padding: 6px 10px;
  }
  .sale-capt {
    font-size: 21px;
  }
  .sale-capt b {
    font-size: 35px;
  }
  .sale-desc p {
    font-size: 19px;
  }
  .contacts-item {
    margin-bottom: 12px;
  }
  .product-inner h1 {
    font-size: 27px;
  }
}
@media screen and (max-width: 991px) {
  body,
  p,
  .header-nav-list,
  .header-contacts {
    font-size: 12px;
  }
  .header-nav-list {
    flex-wrap: wrap;
  }
  button,
  input[type="submit"],
  .btn {
    padding: 9px 17px;
    font-size: 11px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    padding: 9px 15px;
  }
  input,
  textarea,
  button,
  label {
    margin-bottom: 18px;
  }
  p {
    margin-bottom: 15px;
  }
  h1,
  .caption {
    font-size: 30px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 27px;
  }
  h4 {
    font-size: 25px;
  }
  h5 {
    font-size: 21px;
  }
  h6 {
    font-size: 18px;
  }
  ul li:before {
    font-size: 15px;
  }
  .pd-lt-30 {
    padding-left: 23px;
  }
  .pd-hr-20 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-vr-25 {
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .pd-lt-80 {
    padding-left: 60px;
  }
  .pd-tp-75 {
    padding-top: 57px;
  }
  .mr-tp-30 {
    margin-top: 23px;
  }
  .mr-bt-70 {
    margin-bottom: 53px;
  }
  .pd-tp-60 {
    padding-top: 45px;
  }
  .pd-tp-55 {
    padding-top: 42px;
  }
  .pd-tp-40 {
    padding-top: 30px;
  }
  .pd-bt-30 {
    padding-bottom: 27px;
  }
  .pd-vr-75 {
    padding-top: 57px;
    padding-bottom: 57px;
  }
  .pd-vr-90 {
    padding-top: 68px;
    padding-bottom: 68px;
  }
  .pd-vr-60 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .pd-bt-20 {
    padding-bottom: 15px;
  }
  .header-nav-list > li {
    margin-right: 30px;
  }
  .header-nav-list > li ul li a {
    padding: 6px 15px;
  }
  .header-nav-list > li > a {
    padding: 9px 0;
  }
  .header-title {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 30px;
    font-size: 30px;
  }
  .catalog-item {
    padding: 12px;
  }
  .mr-tp-20 {
    margin-top: 15px;
  }
  .modals-inner-capt {
    font-size: 17px;
  }
  .header-contacts-small {
    font-size: 10px;
  }
  .pd-hr-40 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .map {
    height: 300px;
  }
  .mr-tp-40 {
    margin-top: 30px;
  }
  footer {
    padding: 30px 0 23px;
  }
  .copyright {
    margin-top: 15px;
    font-size: 11px;
  }
  .footer-nav {
    margin: 0 0 23px;
  }
  .footer-nav > li {
    margin-bottom: 12px;
  }
  .policy,
  .breadcrumbs,
  .catalog-capt {
    font-size: 11px;
  }
  .catalog-block a {
    padding: 6px 12px;
    padding-right: 28px;
  }
  .catalog-capt {
    padding: 6px 12px;
  }
  .price-val {
    font-size: 27px;
  }
  .price-currency {
    font-size: 15px;
  }
  .product-desc-nav-item {
    padding: 9px 19px;
    width: 143px;
    font-size: 11px;
  }
  .product-desc-item {
    padding-top: 15px;
  }
  .charact-list li {
    margin-bottom: 12px;
  }
  .category-item .catalog-item-capt,
  .category-item .price-currency {
    font-size: 11px;
  }
  .category-item .price-val {
    font-size: 18px;
  }
  .category-btn {
    padding: 6px 9px;
  }
  .sale-capt {
    font-size: 20px;
  }
  .sale-capt b {
    font-size: 32px;
  }
  .sale-desc p {
    font-size: 18px;
  }
  .contacts-item {
    margin-bottom: 12px;
  }
  .mobile-burger {
    display: block;
    border: 2px solid #fff;
  }
  .header-nav-wrap {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-nav {
    position: fixed;
    padding: 30px;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: #7bb638;
    z-index: 11;
  }
  .header-nav.active {
    display: block;
  }
  .header-nav-list {
    font-size: 14px;
  }
  .header-nav-list li {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }
  .header-nav-list li ul {
    display: block;
    position: inherit;
  }
  .header-nav-list > li ul {
    opacity: 1;
    pointer-events: auto;
  }
  .header-nav-list > li ul li a {
    border: none;
  }
  .close-nav {
    display: block;
    float: right;
    font-size: 18px;
    color: #fff;
  }
  .header-title {
    width: 100%;
    text-align: center;
  }
  .modals-inner,
  .modals-content {
    width: 100%;
    max-width: 500px;
  }
  .menu-cat-wrap,
  .close-nav-catalog {
    display: block;
  }
  .menu-cat-wrap {
    margin-bottom: 10px;
  }
  .menu-cat-wrap .btn {
    font-size: 15px;
  }
  .close-nav-catalog {
    position: absolute;
    top: 20px;
    right: 30px;
    margin: 0;
    padding: 0;
    color: #6b6a6a;
    background: none;
    box-shadow: none;
  }
  .close-nav-catalog:hover {
    color: #7bb638;
  }
  .catalog_wrap {
    width: 100%;
    padding: 10px;
    background: #f7f7f7;
  }
  .catalog_wrap.active {
    display: block;
  }
  .catalog-capt {
    display: none;
  }
  h1 {
    text-align: center;
  }
  header .close-nav {
    position: absolute;
    top: 15px;
    right: 30px;
    z-index: 3;
  }
  .product-inner h1 {
    font-size: 25px;
  }
  .main-content {
    min-height: inherit;
  }
}
@media screen and (max-width: 767px) {
  button,
  input[type="submit"],
  .btn {
    padding: 9px 16px;
    font-size: 10px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    padding: 9px 14px;
  }
  input,
  textarea,
  button,
  label {
    margin-bottom: 18px;
  }
  p {
    margin-bottom: 14px;
  }
  h1,
  .caption {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 25px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 17px;
  }
  ul li:before {
    font-size: 14px;
  }
  .pd-lt-30 {
    padding-left: 21px;
  }
  .pd-hr-20 {
    padding-left: 14px;
    padding-right: 14px;
  }
  .pd-vr-25 {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .pd-lt-80 {
    padding-left: 56px;
  }
  .pd-tp-75 {
    padding-top: 53px;
  }
  .mr-tp-30 {
    margin-top: 21px;
  }
  .footer-row .mr-tp-30 {
    margin-top: 40px;
  }
  .mr-bt-70 {
    margin-bottom: 49px;
  }
  .pd-tp-60 {
    padding-top: 42px;
  }
  .pd-tp-55 {
    padding-top: 39px;
  }
  .pd-tp-40 {
    padding-top: 28px;
  }
  .pd-bt-30 {
    padding-bottom: 25px;
  }
  .pd-vr-75 {
    padding-top: 53px;
    padding-bottom: 53px;
  }
  .pd-vr-90 {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .pd-vr-60 {
    padding-top: 42px;
    padding-bottom: 42px;
  }
  .pd-bt-20 {
    padding-bottom: 14px;
  }
  .header-nav-list > li {
    margin-right: 28px;
  }
  .header-nav-list > li ul li a {
    padding: 5px 14px;
  }
  .header-nav-list > li > a {
    padding: 9px 0;
  }
  .header-title {
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 28px;
    font-size: 28px;
  }
  .catalog-item {
    padding: 11px;
  }
  .mr-tp-20 {
    margin-top: 14px;
  }
  .modals-inner-capt {
    font-size: 16px;
  }
  .header-contacts-small {
    font-size: 10px;
  }
  .pd-hr-40 {
    padding-left: 28px;
    padding-right: 28px;
  }
  .map {
    height: 280px;
  }
  .mr-tp-40 {
    margin-top: 28px;
  }
  footer {
    padding: 28px 0 21px;
  }
  .copyright {
    margin-top: 14px;
    font-size: 10px;
  }
  footer .col-xs-12.col-sm-2.mr-tp-30,
  .footer-nav {
    display: none;
  }
  .footer-nav > li {
    margin-bottom: 11px;
  }
  .policy,
  .breadcrumbs,
  .catalog-capt {
    font-size: 12px;
  }
  .catalog-block a {
    padding: 5px 11px;
    padding-right: 28px;
  }
  .catalog-capt {
    padding: 5px 11px;
  }
  .price-val {
    font-size: 25px;
  }
  .product-desc-nav-item {
    padding: 9px 18px;
    width: 133px;
    font-size: 10px;
  }
  .product-desc-item {
    padding-top: 14px;
  }
  .charact-list li {
    margin-bottom: 11px;
  }
  .category-item .price-val {
    font-size: 17px;
  }
  .category-btn {
    padding: 5px 9px;
  }
  .sale-capt {
    font-size: 19px;
  }
  .sale-capt b {
    font-size: 25px;
  }
  .sale-desc p {
    font-size: 17px;
  }
  .contacts-item {
    margin-bottom: 11px;
  }
  header .middle-vr {
    display: block;
  }
  header {
    text-align: center;
  }
  .logo {
    max-width: 200px;
    margin: 0 auto 15px;
  }
  .logo,
  .header-contacts-item {
    display: block;
    margin-bottom: 15px;
  }
  .header-nav {
    text-align: left;
  }
  .catalog-item {
    display: inline-block;
    max-width: 255px;
  }
  footer,
  footer .header-contacts-small {
    text-align: center;
  }
  .category-item {
    margin-top: 20px;
    text-align: center;
  }
  .sale-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .product-inner h1 {
    font-size: 21px;
  }
}
@media screen and (max-width: 460px) {
  .col-xxs-12 {
    width: 100%;
  }
  .header-contacts-item,
  .header-contacts-item div {
    display: none;
  }
  .header-contacts-item-adr {
    display: block;
    margin-bottom: 0;
  }
  .header-contacts-item-tel,
  footer .header-contacts-item {
    display: block;
  }
  .modals-content-map {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 480px) {
  .header-contacts-item-tel {
    display: flex;
    margin-bottom: 5px;
  }
  .header-contacts-item-tel a:first-child {
    margin-right: 30px;
  }
  .header-contacts-item-mail {
    margin: 0;
  }
  .header-contacts-item-adr {
    text-align: left;
  }
  .header-contacts-item-tel .glyphicon {
    margin-right: 10px;
  }
  .header-contacts-item-adr .glyphicon {
    margin-right: 8px;
  }
  .header-contacts-item-mail {
    padding-left: 25px;
  }
  .logo {
    margin: 0 0 15px;
    text-align: left;
  }
}
@media (max-width: 350px) {
  .header-contacts-item-tel {
    flex-wrap: wrap;
  }
  .header-contacts-item-mail {
    margin-top: 3px;
  }
}
.green_line {
  position: relative;
  margin-top: 50px;
  margin-bottom: 35px;
}
.green_line::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35%;
  height: 65px;
  background-color: #7bb638;
}
@media screen and (max-width: 900px) {
  .green_line::after {
    height: 35px;
  }
}
@media screen and (max-width: 600px) {
  .green_line::after {
    height: 15px;
  }
}
.green_line--bottom {
  margin-top: 75px;
  margin-bottom: 75px;
}
@media screen and (max-width: 900px) {
  .green_line--bottom {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 600px) {
  .green_line,
  .green_line--bottom {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.main_text {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 35px;
  z-index: 1;
}
.main_text::before,
.main_text::after {
  display: none;
}
.main_text h2 {
  width: 100%;
  margin: 40px 0 50px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .main_text h2 {
    margin: 25px 0 30px;
  }
}
.main_text__img {
  width: 49%;
  position: relative;
  padding: 45px 45px 60px 0;
  background-image: linear-gradient(to right, #fff 27.5%, #f4b62f 27.5%);
}
.main_text__text {
  width: 46%;
}
@media screen and (max-width: 767px) {
  .main_text__text {
    width: 56%;
  }
  .main_text__img {
    align-self: flex-start;
    width: 40%;
    padding: 15px 15px 25px 0;
  }
}
@media screen and (max-width: 599px) {
  .main_text__text {
    width: 100%;
  }
  .main_text__img {
    width: 100%;
    margin-bottom: 20px;
  }
}
.main_text__text p {
  font-size: 16px;
  line-height: 1.4;
  color: #280000;
}
.main_text__text p a {
  color: inherit;
}
.main_text__text p a:hover {
  text-decoration: underline;
}
.main_text__text .big {
  font-size: 30px;
  line-height: 1.2;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
}
@media screen and (max-width: 900px) {
  .main_text__text .big {
    font-size: 25px;
  }
}
@media screen and (max-width: 600px) {
  .main_text__text .big {
    font-size: 20px;
  }
}
.preffs {
  padding-top: 30px;
  background-image: url("/assets/images/main_back.jpg");
  background-position: center;
  background-size: cover;
  color: #fff;
}
.preffs h3 {
  text-align: center;
  color: #fff;
}
.preffs_list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 35px 0 30px;
  list-style: none;
}
.preffs_list li {
  display: flex;
  align-items: center;
  position: relative;
  width: 32%;
  min-height: 90px;
  margin-bottom: 30px;
  padding-left: 105px;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
}
.preffs_list li:hover {
  transform: scale(1.05);
}
.preffs_list li > a {
  color: inherit;
}
@media screen and (max-width: 720px) {
  .preffs_list li {
    width: 48%;
  }
}
@media screen and (max-width: 560px) {
  .preffs_list {
    padding: 20px 0;
  }
  .preffs_list li {
    width: 100%;
  }
  .preffs_list li br {
    display: none;
  }
}
.preffs_list li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  background-image: url("/assets/images/main_pref_back.png");
}
.preffs_list li::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
}
.pref_1::after {
  background-image: url("/assets/images/main_pref_1.png");
}
.pref_2::after {
  background-image: url("/assets/images/main_pref_2.png");
}
.pref_3::after {
  background-image: url("/assets/images/main_pref_3.png");
}
.pref_4::after {
  background-image: url("/assets/images/main_pref_4.png");
}
.pref_5::after {
  background-image: url("/assets/images/main_pref_5.png");
}
.pref_6::after {
  background-image: url("/assets/images/main_pref_6.png");
}
.main_catalog {
  padding-bottom: 40px;
}
.main_catalog h2 {
  margin-bottom: 25px;
}
.main_catalog__slider {
  position: relative;
}
.main_catalog .swiper-slide {
  display: flex;
  flex-direction: column;
}
.main_catalog .swiper-slide .item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.main_catalog .swiper-button-next,
.main_catalog .swiper-button-prev {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 14px;
  height: 26px;
  margin-top: -13px;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
  background-image: url("/assets/images/main_catalog_arrow.png");
  box-shadow: none;
  cursor: pointer;
}
.main_catalog .swiper-button-next {
  right: -35px;
  transform: scale(-1, 1);
}
.main_catalog .swiper-button-prev {
  left: -35px;
}
.swiper-button-disabled {
  opacity: 0.2;
}
@media screen and (max-width: 1200px) {
  .main_catalog {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-height: 570px) {
  #search.fancybox-content {
    margin-top: 4vh;
    vertical-align: top;
  }
}
.dg-widge iframe {
  width: 100%;
}
.category-item > div.noresult {
  display: block;
  margin: 20px 15px 0;
  padding: 30px 50px 50px;
  background-color: #f7f7f7;
}
@media screen and (max-width: 680px) {
  .category-item > div.noresult {
    padding: 20px 20px 30px;
  }
}
.noresul {
  margin-bottom: 55px;
}
.noresult > p {
  margin: 0;
  padding: 0 0 23px;
  padding-left: 45px;
  text-align: left;
}
@media screen and (max-width: 520px) {
  .noresul {
    margin-bottom: 20px;
  }
  .noresult > p {
    padding-bottom: 15px;
  }
}
.noresult__warn {
  position: relative;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
.noresult__warn::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 27px;
  height: 27px;
  background-image: url("/assets/images/search_no.png");
}
.noresult > p a {
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  font-size: 16px;
  white-space: nowrap;
}
.noresult form {
  display: grid;
  grid-template-columns: 47% 47%;
  justify-content: space-between;
  align-items: center;
  padding: 35px 35px 40px;
  background-color: #fff;
  border-bottom: 20px solid #7bb638;
  border-radius: 2px;
}
@media screen and (max-width: 767px) {
  .noresult form {
    grid-template-columns: 49% 49%;
    padding: 20px 15px 25px;
  }
}
@media screen and (max-width: 520px) {
  .noresult form {
    display: block;
  }
}
.noresult form h3 {
  position: relative;
  grid-column: 1/3;
  margin: 0;
  padding: 0 0 13px;
  text-align: center;
  font-size: 22px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
.noresult form h3::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 33.5px);
  width: 67px;
  height: 3px;
  background-color: rgb(123, 182, 56);
}
.noresult form > p {
  position: relative;
  grid-column: 1/3;
  margin: 0;
  padding: 15px 0 23px;
  text-align: center;
  line-height: 1.2;
}
.noresult .form-group--textarea {
  grid-column: 2/3;
  grid-row: 3/5;
}
.noresult .form-group input,
.noresult .form-group textarea {
  margin-bottom: 0;
}
.noresult .form-group textarea {
  height: 110px;
  min-height: 0;
}
.noresult .form-group {
  margin-bottom: 18px;
}
.noresult .btn-wrapper {
  text-align: right;
}
.noresult button {
  display: inline-block;
  margin: 0;
  padding: 14px 50px;
  background: #f5b62f;
  border-radius: 3px;
  box-shadow: 0 10px 20px #54423154;
  text-align: center;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  color: #fff;
  font-size: 16px;
  line-height: 1.2;
}
@media screen and (max-width: 520px) {
  .noresult .btn-wrapper {
    text-align: center;
  }
  .noresult button {
    margin: 15px auto 0;
  }
}
.contacts_block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding: 30px 0 45px;
  position: relative;
}
.contacts_block:nth-child(odd)::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  left: -10%;
  top: 0;
  background-color: #f7f4f4;
}
.contacts_block__text {
  width: 46%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .contacts_block {
    padding: 20px 15px 25px;
  }
  .contacts_block__text {
    width: 100%;
    margin-bottom: 20px;
  }
  .contacts_block:nth-child(even)::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0;
    top: 0;
    background-color: #f7f4f4;
  }
}
.contacts_block__text p {
  margin: 0;
  padding: 0 0 15px;
  font-size: 16px;
  line-height: 1.2;
}
.contacts_block__text a {
  color: #627151;
  white-space: nowrap;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
.contacts_block__images {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: auto -4px 0;
  padding-top: 20px;
}
.contacts_block__images > img {
  margin: 0 4px 4px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .contacts_block__images {
    padding-top: 0;
  }
}
.contacts_block__map {
  width: 50%;
  position: relative;
  z-index: 10;
}
.contacts_block__map iframe {
  height: 100% !important;
  min-height: 310px;
}
@media screen and (max-width: 768px) {
  .contacts_block__map {
    width: 100%;
  }
}

.contacts_job {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 40px;
  padding: 0;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
 
}
.contacts_job__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  background-color: rgb(248, 248, 248);

  &_title {
    margin: 0 0 40px;
    font-size: 38px;
    line-height: 1.1;
    font-family: "Montserrat-Bold";

    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 25px;
    }

    strong {
      position: relative;
      display: inline-block;
      margin-top: 5px;
      padding-left: 30px;
      font-weight: 400;
      font-size: 21px;
      text-align: left;
      font-family: "Montserrat-Medium";

      @media screen and (max-width: 991px) {
        font-size: 19px;
      }
      @media screen and (max-width: 768px) {
        font-size: 17px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: rgb(53, 159, 117);

        @media screen and (max-width: 768px) {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  &_tel {
    margin: 0;
    text-align: center;
    line-height: 1.1;
    font-family: "Montserrat-Medium";

    span {
      display: block;
      width: 277px;
      max-width: 100%;
      margin-bottom: 20px;
      padding: 15px;
      color: #fff;
      font-size: 18px;
      border-radius: 10px;
      background-color: rgb(53, 159, 117);

      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        font-size: 16px;
      }
    }

    a {
      position: relative;
      display: inline-block;
      padding-left: 28px;
      font-size: 19px;
      color: inherit;

      @media screen and (max-width: 768px) {
        font-size: 17px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background-image: url('/assets/images/black_call_icon.svg');
        background-size: 22px;
        background-position: center;
      }
    }
  }
}
.contacts_job__img {
  align-self: center;
  width: calc(50% - 15px);
  border-radius: 10px;
}
@media screen and (max-width: 600px) {
  .contacts_job__text,
  .contacts_job__img {
    width: 100%;
  }
}


.fotorama__fullscreen-icon {
  background: url("/assets/images/fotorama_big.png") no-repeat scroll right 0
    rgba(0, 0, 0, 0) !important;
  width: 100% !important;
  height: 100% !important;
  right: 0 !important;
  top: 0 !important;
  z-index: 10 !important;
}
.rubrics_menu {
  position: relative;
  margin: 23px 0 30px;
  padding: 0 20px;
}
.rubrics_menu .swiper-slide {
  width: auto;
}
.rubrics_menu a {
  display: inline-block;
  margin: 0 8px;
  padding: 3px 0;
  font-size: 15px;
  color: #000000;
  border-bottom: 1px dashed #000000;
}
@media screen and (max-width: 600px) {
  .rubrics_menu {
    margin: 23px 0;
  }
  .rubrics_menu a {
    margin: 0 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {
  .rubrics_menu a {
    font-size: 13px;
  }
}
.rubrics_menu a:hover {
  color: #4b8806;
  border-bottom-color: #4b8806;
}

.rubrics_menu .swiper-button-next,
.rubrics_menu .swiper-button-prev {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 20px;
  height: 44px;
  margin: -22px 0 0;
  border-radius: 3px;
  border-width: 1px;
  border-color: rgb(226, 226, 226);
  border-style: solid;
  background-color: rgb(247, 247, 247);
  font-size: 0;
  background-image: url("/assets/images/rubrics_arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 2.939px 4.045px 15px 0px rgba(83, 75, 75, 0.2);
}
.rubrics_menu .owl-nav button:hover {
  box-shadow: none;
}
.rubrics_menu .swiper-button-next {
  right: 0;
}
.rubrics_menu .swiper-button-prev {
  left: 0;
  transform: scale(-1, 1);
}
.catalog_fill {
  background-color: #f1f1f1;
  border-radius: 2px;
  padding: 15px 5px;
  padding-left: 60px;
  font-size: 15px;
  line-height: 1.3;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  background-image: url("/assets/images/catalog_fill.png");
  background-repeat: no-repeat;
  background-position: 30px center;
}
@media screen and (min-width: 992px) {
  .catalog_fill {
    padding-right: 30px;
  }
}
@media screen and (max-width: 400px) {
  .catalog_fill {
    padding-left: 30px;
    background-position: 10px 18px;
  }
}
.catalog_fill a {
  color: inherit;
  white-space: nowrap;
}
.catalog_fill span {
  font-size: 20px;
}
.errorpage {
  padding-top: 80px;
  padding-bottom: 240px;
  background-image: url("/assets/images/error404.jpg");
  background-repeat: no-repeat;
  background-position: center top;
}
.errorpage .container {
  max-width: 860px;
  min-height: 0;
  margin: 0 auto;
}
.errorpage .main-content-inners {
  display: flex;
  justify-content: space-between;
}
.errorpage .title {
  max-width: 285px;
}
.errorpage h1 {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-align: left;
  text-transform: none;
  color: #3a3a3c;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
  font-weight: 400;
  line-height: 1;
}
.errorpage h1 b {
  color: #ffffff;
  font-size: 100px;
}
.errorpage .text p {
  max-width: 395px;
  font-size: 18px;
  text-align: left;
  line-height: 1.2;
}
.errorpage .btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 35px -10px 0;
}
.errorpage .btns a {
  margin: 0 10px 10px;
  padding: 15px 30px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
  border-radius: 5px;
  background-color: rgb(123, 182, 56);
}
.errorpage .btns a:hover {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 767px) {
  .errorpage .text p {
    max-width: 330px;
  }
}
@media screen and (max-width: 660px) {
  .errorpage .main-content-inners {
    flex-wrap: wrap;
  }
  .errorpage .text p {
    max-width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  .errorpage .btns {
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .errorpage {
    padding-top: 60px;
    padding-bottom: 80px;
    background-position: 35% top;
  }
}
@media screen and (max-width: 340px) {
  .errorpage .btns a {
    padding: 15px 20px;
  }
}
// .errorpage + footer {
//   margin-top: 0;
// }
.smenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 30px 0 45px;
  color: #000000;
  background-color: #f1f1f1;
  -webkit-box-shadow: 0px 2px 7.68px 0.32px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 2px 7.68px 0.32px rgba(0, 0, 0, 0.45);
  z-index: 20;
}
@media screen and (max-width: 1199px) {
  .smenu {
    padding: 20px 0 30px;
  }
}
@media screen and (min-width: 992px) {
  .smenu {
    overflow-y: scroll;
  }
  .smenu > ul.container {
    margin: 0 auto;
    padding: 0 15px;
    /*  column-count: 4; */
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
    grid-column-gap: 10px;
    align-items: flex-start;
    list-style: none;
  }
  /* .smenu > ul.container > li:nth-child(1) {
    grid-column: 2/3;
    grid-row: 4/5;
  }
  .smenu > ul.container > li:nth-child(2) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .smenu > ul.container > li:nth-child(3) {
    grid-column: 1/2;
    grid-row: 2/5;
  }
  .smenu > ul.container > li:nth-child(4) {
    grid-column: 2/3;
    grid-row: 2/4;
  }
  .smenu > ul.container > li:nth-child(5) {
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .smenu > ul.container > li:nth-child(6) {
    grid-column: 3/4;
    grid-row: 2/4;
  }
  .smenu > ul.container > li:nth-child(7) {
    grid-column: 4/5;
    grid-row: 3/4;
  }
  .smenu > ul.container > li:nth-child(8) {
    grid-column: 4/5;
    grid-row: 1/2;
  }
  .smenu > ul.container > li:nth-child(9) {
    grid-column: 3/4;
    grid-row: 4/5;
  }
  .smenu > ul.container > li:nth-child(10) {
    grid-column: 3/4;
    grid-row: 1/2;
  }
  .smenu > ul.container > li:nth-child(11) {
    grid-column: 4/5;
    grid-row: 4/5;
  }
  .smenu > ul.container > li:nth-child(12) {
    grid-column: 4/5;
    grid-row: 2/3;
  } */

  .smenu > ul.container > li:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .smenu > ul.container > li:nth-child(2) {
    grid-column: 2/3;
    grid-row: 1/2;
  }
   .smenu > ul.container > li:nth-child(3) {
    grid-column: 3/4;
    grid-row: 1/3;
  }
  .smenu > ul.container > li:nth-child(4) {
    grid-column: 4/5;
    grid-row: 1/2;
  }
  .smenu > ul.container > li:nth-child(5) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .smenu > ul.container > li:nth-child(6) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .smenu > ul.container > li:nth-child(7) {
    grid-column: 4/5;
    grid-row: 2/3;
  }
  .smenu > ul.container > li:nth-child(8) {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .smenu > ul.container > li:nth-child(9) {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .smenu > ul.container > li:nth-child(10) {
    grid-column: 3/4;
    grid-row: 3/4;
  }
  .smenu > ul.container > li:nth-child(11) {
    grid-column: 4/5;
    grid-row: 3/4;
  }
  .smenu > ul.container > li:nth-child(12) {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .smenu > ul.container > li:nth-child(13) {
    grid-column: 2/3;
    grid-row: 4/5;
  }

  .smenu > ul.container > li {
    padding: 0;
    /* for Chrome and Edge */
    break-inside: avoid-column;
    /* for Firefox */
    display: inline-grid;
    page-break-inside: avoid;
  }
  /* for Chrome and Edge */
  @supports (break-inside: avoid-column) {
    .smenu > ul.container > li {
      display: block;
    }
  }
  .smenu > ul.container > li:before,
  .smenu > ul.container:before,
  .smenu > ul.container:after {
    display: none;
  }
  .smenu > ul.container > li {
    margin-bottom: 10px;
    list-style: none;
  }
  .smenu > ul.container > li > a {
    position: relative;
    z-index: 2;
    display: block;
    min-height: 35px;
    padding: 14px 10px;
    padding-left: 55px;
    color: #0b171f;
    font-family: "Montserrat-ExtraBold", Verdana, Helvetica;
    text-transform: uppercase;
    font-size: 16px;
  }
  @media screen and (max-width: 1199px) {
    .smenu > ul.container > li > a {
      font-size: 14px;
    }
  }
  .smenu > ul.container > li > a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 47px;
    height: 39px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(88%) sepia(24%) saturate(5422%) hue-rotate(343deg)
      brightness(96%) contrast(97%);
  }
  .smenu > ul.container > li:nth-child(1) > a::before {
    background-image: url(/assets/images/smenu_icon_1.svg);
    background-size: 42px;
  }
  .smenu > ul.container > li:nth-child(2) > a::before {
    background-image: url(/assets/images/smenu_icon_2.svg);
    background-size: 42px;
  }
  .smenu > ul.container > li:nth-child(3) > a::before {
    background-image: url(/assets/images/smenu_icon_3.svg);
    background-size: 36px;
  }
  .smenu > ul.container > li:nth-child(4) > a::before {
    background-image: url(/assets/images/smenu_icon_4.svg);
    background-size: 47px;
  }
  .smenu > ul.container > li:nth-child(5) > a::before {
    background-image: url(/assets/images/smenu_icon_5.svg);
    background-size: 30px;
  }
  .smenu > ul.container > li:nth-child(6) > a::before {
    background-image: url(/assets/images/smenu_icon_6.svg);
    background-size: 30px;
  }
  .smenu > ul.container > li:nth-child(7) > a::before {
    background-image: url(/assets/images/smenu_icon_7.svg);
    background-size: 39px;
  }
  .smenu > ul.container > li:nth-child(8) > a::before {
    background-image: url(/assets/images/smenu_icon_8.svg);
    background-size: 35px;
  }
  .smenu > ul.container > li:nth-child(9) > a::before {
    background-image: url(/assets/images/smenu_icon_9.svg);
    background-size: 33px;
  }
  .smenu > ul.container > li:nth-child(10) > a::before {
    background-image: url(/assets/images/smenu_icon_10.svg);
    background-size: 32px;
  }
  .smenu > ul.container > li:nth-child(11) > a::before {
    background-image: url(/assets/images/smenu_icon_11.svg);
    background-size: 36px;
  }
  .smenu > ul.container > li:nth-child(12) > a::before {
    background-image: url(/assets/images/smenu_icon_12.svg);
    background-size: 37px;
  }
  .smenu > ul.container > li:nth-child(13) > a::before {
    top: 12px;
    // background-image: url(/assets/images/avtotov.svg);
    background-size: 39px;
  }
  .smenu > ul.container > li > a.active,
  .smenu > ul.container > li > a:hover {
    color: #fff;
    background-color: #f5b62f;
    border-radius: 2px;
  }
  .smenu > ul.container > li > a.active::before,
  .smenu > ul.container a:hover::before {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(208deg)
      brightness(109%) contrast(101%);
  }
  .smenu > ul.container > li > ul {
    margin: 0;
    padding: 0;
    padding-left: 30px;
    list-style: none;
  }
  .smenu > ul.container > li > ul li {
    margin: 0;
    padding: 0;
  }
  .smenu > ul.container > li > ul > li > a {
    position: relative;
    font-family: "Montserrat-Medium", Verdana, Helvetica;
  }
  .smenu > ul.container > li > ul > li.submenu .submenu_arrow {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background-image: url("data:image/svg+xml,%3Csvg transform='rotate(-90)' fill='%23619a21' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 492 492' style='enable-background:new 0 0 492 492;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px;
  }
  .smenu > ul.container > li > ul > li.submenu .submenu_arrow.opened {
    transform: rotate(180deg);
  }
  .smenu > ul.container > li > ul li::before {
    display: none;
  }
  .smenu > ul.container > li > ul li > a {
    display: inline-block;
    padding: 5px 25px;
    font-size: 14px;
    color: #000;
    line-height: 1.1;
  }
  .smenu > ul.container > li > ul li > a {
    padding: 8px 25px;
  }
  .smenu > ul.container > li > ul > li > ul {
    display: none;
  }
  /*     .smenu > ul.container > li > ul > li.submenu:hover > ul {
    	display: block;
    } */
  .smenu > ul.container > li > ul > li > ul {
    margin-bottom: 10px;
    padding: 5px 0;
    border-top: 2px solid #7bb638;
    background-color: #fff;
  }

  .smenu > ul.container > li > ul li > a.active:hover,
  .smenu > ul.container > li > ul a:hover {
    color: #4c8700;
  }
  .smenu > ul.container > li > ul > li > ul ul {
    display: block;
    margin: 0;
    padding-left: 40px;
  }
  .smenu > ul.container > li > ul > li > ul ul a {
    padding: 3px 0;
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .fixed .smenu {
    max-height: calc(100vh - 55px);
    overflow-y: scroll;
  }
  .fixed .smenu a {
    display: block;
    padding: 7px 15px;
    padding-right: 28px;
    border-bottom: 1px solid #e2e2e2;
    color: #000;
  }
  .fixed .smenu .submenu > a {
    background-repeat: no-repeat;
    background-position: calc(100% - 9px) center;
    background-image: url("/assets/images/aside_arrow.png");
  }
  .fixed .smenu li:last-child a {
    border: none;
  }
  .fixed .smenu li {
    position: static;
    margin: 0;
    padding: 0;
  }
  .fixed .smenu li::before {
    display: none;
  }
  .fixed .smenu > ul.container {
    display: block;
    padding: 0;
  }
  .fixed .smenu > ul.container > li {
    display: block;
  }
  .fixed .smenu li:hover > a,
  .fixed .smenu li.active > a {
    background-color: #7bb638;
    color: #fff;
  }
  .fixed .smenu .submenu:hover > a,
  .fixed .smenu .submenu.active > a {
    background-image: url("/assets/images/aside_arrow_act.png");
  }
  .fixed .smenu .submenu > a.m-open {
    background-color: #7bb638;
    color: #fff;
    background-image: url("/assets/images/aside_arrow_act_down.png");
  }
  .fixed .smenu li ul {
    display: none;
    width: 100%;
    padding: 0;
    margin: 0;
    background: transparent;
    list-style-type: none;
    font-size: 15px;
    z-index: 2;
  }
  .fixed .smenu > ul.container > li {
    position: relative;
  }
  .fixed .smenu > ul.container > li > ul {
    padding-left: 1px;
    top: 0;
  }
  .fixed .smenu ul.onhover {
    display: block;
  }
  .fixed .smenu li ul a {
    padding: 6px 12px;
    font-size: 13px;
    background-color: #fff;
  }
  .fixed .smenu li.submenu > a {
    padding-right: 28px;
  }
}
.search_modal__result:empty {
  box-shadow: none;
}
.search_modal__result {
  /* padding-top: 7px; */
  background-color: transparent;
}
.search_modal__result > div {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  max-height: 92vh;
  overflow-y: scroll;
}
.search_modal__result > .reset-div {
  overflow-y: unset;
}
.reset-div p {
  margin: 0;
  text-align: center;
  background-color: #ffe0eb;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.55);
}


@media screen and (max-width: 1380px) {
	.search_modal__result > div {
		max-height: calc(100vh - 80px);
	}
}
@media screen and (max-width: 768px) {
	.search_modal__result > div {
		max-height: calc(100vh - 290px);
	}
}
.search_modal__block {
  padding-bottom: 6px;
  background-color: #fff;
}
.search_modal__block a {
  color: inherit;
}
.search_modal__block_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 10px 20px;
  color: #fff;
  font-size: 15px;
  background-color: #7bb638;
  // background-image: url("/assets/images/search_modal__block_header_green.png");
  background-repeat: no-repeat;
  background-position: left;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
.search_modal__block:nth-of-type(1) .search_modal__block_header {
  background-color: #f5b62f;
  // background-image: url("/assets/images/search_modal__block_header_yellow.png");
}
#search .search_modal__block:nth-of-type(1) .search_modal__block_header {
  background-color: #7bb638;
  // background-image: url("/assets/images/search_modal__block_header_green.png");
}
.search_modal__block_header a {
  text-decoration: underline;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
}
.search_modal__block_header a:hover {
  text-decoration: none;
}
.search_modal__block ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.search_modal__block ul li:before {
  display: none;
}
.search_modal__block ul li {
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.search_modal__block ul li a {
  display: block;
  padding: 10px 20px;
  line-height: 1.1;
  text-align: left;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
}
.search_modal__block ul a:hover {
  background-color: #e8e8e8;
}
@media screen and (max-width: 414px) {
  .search_modal__block_header,
  .search_modal__block ul li {
    font-size: 13px;
  }
}
.dark-header .header-search-wrap {
  align-self: center;
}
@media (max-width: 1140px) {
  .dark-header .header-search-wrap {
    align-self: flex-start;
  }
}
.dark-header .search_modal__result {
  position: absolute;
  left: 15px;
  top: 100%;
  z-index: 10;
  width: calc(100% - 30px);
}
.header-nav-wrap .search_modal__result {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
  padding-top: 0;
}
@media (min-width: 992px) {
  .header-nav-wrap .search_modal__result {
    padding-top: 5px;
  }
}

.fix-search-wrap {
  display: none;
}
.fixed .fix-search-wrap {
  display: block;
}
.breadcrumbs li:last-child a {
  /* pointer-events: none; */
  color: #000;
}
.breadcrumbs li:last-child a + span,
.breadcrumbs li:last-child div {
  display: none !important;
}
.tury_products {
  margin-bottom: 30px;
}
.tury_products p {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 15px;
}
.tury_products a {
  display: inline-block;
  white-space: nowrap;
  color: inherit;
}
.tury_products img {
  display: block;
  margin-bottom: 4px;
}
.tury_products__text {
  padding: 40px 35px 20px;
  background-color: #f7f7f7;
}
.tury_products__list {
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
  font-size: 14px;
}
.tury_products__list li {
  margin-bottom: 3px;
}
.tury_products__list li::before {
  content: "•";
  color: #7bb638;
  display: inline-block;
  margin-left: 5px;
}
@media screen and (max-width: 560px) {
  .tury_products__text {
    padding: 25px 10px 15px;
  }
}
.img-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0 20px;
}
.img-row img {
  max-width: 49%;
  margin-bottom: 20px;
}
@media screen and (max-width: 560px) {
  .img-row {
    padding: 0;
  }
  .img-row img {
    max-width: 100%;
  }
}
.delivery-page {
  padding-top: 20px;
  padding-bottom: 70px;
}
.delivery-page h1 {
  padding: 25px 0 20px;
}
@media screen and (min-width: 1360px) {
  .delivery-page h1 {
    font-size: 40px;
  }
}
.delivery-page p {
  line-height: 1.45;
}
.delivery-page_cars {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 160px;
  min-height: 90px;
  background-color: #efefef;
  margin: 35px 0;
  font-size: 18px;
}
.delivery-page_cars::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  left: 30px;
  top: 0;
  background: url("/assets/images/delivery-page_cars.svg") center center / 50px no-repeat,
    #f5b730;
}
.delivery-page_phones {
  font-size: 18px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
.delivery-page_phones a {
  position: relative;
  display: inline-block;
  color: inherit;
  margin-left: 30px;
  border-bottom: 1px dashed #79b33c;
}
.delivery-page_phones a:hover {
  color: #79b33c;
}
.delivery-page_phones a::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: -28px;
  background: url("/assets/images/delivery_phone.svg") center center / 100% no-repeat;
}
@media screen and (max-width: 600px) {
  .delivery-page_phones {
    text-align: center;
  }
  .delivery-page_cars {
    padding-left: 100px;
    font-size: 16px;
  }
  .delivery-page_cars::before {
    width: 75px;
    left: 10px;
  }
}
@media screen and (max-width: 400px) {
  .delivery-page_cars {
    padding-left: 80px;
    padding-right: 10px;
  }
  .delivery-page_cars::before {
    width: 60px;
    background-size: 40px;
  }
}
.cart-page {
  padding-top: 15px;
  padding-bottom: 65px;
  min-height: initial;
}
.cart-page::before,
.cart-page::after {
  display: none;
}
.cart-page h1 {
  margin: 10px 0 5px;
}
@media screen and (min-width: 1200px) {
  .cart-page h1 {
    font-size: 44px;
  }
}
.cart_table {
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 8px;
}
.cart_table.loading {
  opacity: 0.2;
}
.cart_table .cart_load {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cart_table th {
  background-color: #e8e8e8;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding: 15px 10px;
}
.cart_table th:first-child {
  border-radius: 5px 0 0 5px;
  text-align: left;
  padding-left: 25px;
}
.cart_table th:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 25px;
}
.cart_table td {
  border: 1px solid #e6e5e5;
  border-left: none;
  border-right: none;
  padding: 10px;
  padding-bottom: 50px;
  text-align: center;
}
.cart_table td:first-child {
  border-left: 1px solid #e6e5e5;
  padding-left: 15px;
  padding-bottom: 10px;
}
.cart_table td:last-child {
  border-right: 1px solid #e6e5e5;
  padding-right: 20px;
  width: 130px;
}
.cart_item__img {
  width: 260px;
}
.cart_item__img a {
  display: block;
  background-color: #e6e6e6;
  padding: 22px;
}
.cart_item__name {
  max-width: 210px;
}
.cart_item__name a {
  display: block;
  text-align: left;
  line-height: 1.3;
  padding-left: 10px;
  color: inherit;
  font-size: 15px;
  font-family: "Montserrat-Bold";
}
.quant_btns {
  display: flex;
  justify-content: center;
  font-weight: 500;
  color: #000;
}
.quant_btns button,
.quant_btns input {
  width: 40px;
  height: 40px;
  font-family: "Montserrat-Medium";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  border-radius: 3px;
  background-color: #e6e6e6;
}
.quant_btns button {
  padding: 0;
  margin: 0;
  box-shadow: none;
  font-size: 23px;
  color: inherit;
}
.quant_btns input {
  margin: 0 4px;
  padding: 5px;
  font-size: 17px;
  font-weight: 500;
}
.cart_item__price,
.cart_item__summ {
  font-size: 15px;
  font-family: "Montserrat-Bold";
}
.cart_item__price b,
.cart_item__summ b {
  font-size: 23px;
}
.cart_remove {
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  background: url("/assets/images/cart_remove.svg") center center / 35px no-repeat;
}
.cart_remove:hover {
  filter: invert(70%) sepia(82%) saturate(1361%) hue-rotate(336deg)
    brightness(98%) contrast(97%);
}
.cart_total {
  margin-bottom: 40px;
  background-color: #7bb638;
  color: #fff;
  font-size: 23px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  padding: 14px 20px;
  border-radius: 5px;
  line-height: 1.2;
  text-align: right;
}
.cart_total small {
  font-size: 70%;
}
.cart_form__title {
  font-family: "Montserrat-ExtraBold";
  font-size: 44px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1140px) {
  .cart_table td {
    padding-bottom: 10px;
  }
  .cart_table td:last-child,
  .cart_table th:last-child {
    width: auto;
    padding-right: 10px;
  }
  .cart_item__img {
    width: auto;
    max-width: 200px;
  }
  .cart_item__img a {
    padding: 10px;
  }
}
@media screen and (max-width: 992px) {
  .cart_item__name a {
    padding-left: 0;
  }
  .cart_item__price b,
  .cart_item__summ b {
    font-size: 20px;
  }
}
@media screen and (max-width: 880px) {
  .cart_table td {
    padding: 10px 5px;
  }
  .cart_table td:first-child {
    padding: 10px 5px 10px 10px;
  }
  .cart_table td:last-child {
    padding: 10px 10px 10px 5px;
  }
  .cart_table th {
    padding: 15px 5px;
  }
  .quant_btns button,
  .quant_btns input {
    width: 34px;
    height: 34px;
  }
}
@media screen and (max-width: 768px) {
  .cart_item__img a {
    padding: 0px;
    background: none;
  }
  .cart_item__name a {
    font-size: 14px;
  }
  .cart_item__price b,
  .cart_item__summ b {
    font-size: 18px;
  }
  .cart_remove {
    background-size: 32px;
  }
}
@media screen and (max-width: 680px) {
  .cart_item__name a {
    font-family: "Montserrat-Medium";
    line-height: 1.2;
  }
  .cart_table {
    border-collapse: collapse;
  }
  .cart_table th {
    display: none;
  }
  .cart_table tr td {
    border: none;
    padding: 3px;
  }
  .cart_table td:last-child {
    padding: 0;
  }
  .cart_table tbody tr {
    position: relative;
    display: block;
    width: 100%;
    padding: 15px 55px;
    padding-left: 160px;
    border: 1px solid #e6e5e5;
    border-bottom: none;
  }
  .cart_table tbody tr:last-child {
    border-bottom: 1px solid #e6e5e5;
  }
  .cart_table td {
    display: block;
    text-align: left;
    width: 100%;
    max-width: initial;
  }
  .cart_table td:nth-child(4)::before,
  .cart_table td:nth-child(5)::before {
    content: attr(data-title);
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
  }
  .cart_table tr .cart_item__img {
    position: absolute;
    border: none;
    top: 25px;
    left: 5px;
    width: 140px;
    padding: 0;
  }
  .cart_remove {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 8px;
    top: 20px;
  }
  .quant_btns {
    justify-content: flex-start;
  }
  .cart_total {
    margin-top: 10px;
  }
}
@media screen and (max-width: 480px) {
  .cart_table tbody tr {
    padding: 12px 10px;
    padding-left: 100px;
  }
  .cart_table tr .cart_item__img {
    top: 15px;
    left: 2px;
    width: 90px;
  }
  .cart_remove {
    left: 25px;
    right: auto;
    top: initial;
    bottom: 12px;
    background-size: 30px;
  }
  .cart_item__summ {
    margin-right: 25px;
  }
  .cart_total {
    text-align: center;
  }
}
.cart_form {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 315px;
  align-items: flex-start;
  column-gap: 13px;
  row-gap: 16px;
}
.cart_form .agreement {
  position: relative;
  margin: 0 0 0 30px;
  font-size: 13px;
  line-height: 1.3;
}
.cart_form .agreement span {
  position: relative;
  display: block;
  padding-left: 40px;
}
.cart_form .agreement span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 27px;
  height: 27px;
  border: 1px solid #e6e5e5;
  border-radius: 2px;
}
.cart_form .agreement input {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 35px;
  height: 40px;
  opacity: 0;
  margin: 0;
}
.agreement input:checked + span::before {
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg fill='none' height='24' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E")
    center center / 80% no-repeat;
}
.cart_form input,
.cart_form textarea {
  padding: 15px;
  border: 1px solid #e6e5e5;
  border-radius: 5px;
  margin: 0;
}
.cart_form input::placeholder,
.cart_form textarea::placeholder {
  color: #949494;
}
.cart_form .cart_form__textarea {
  grid-column: 1/-2;
  min-height: 110px;
}
.cart_form .order_submit {
  box-shadow: none;
  margin: 0 0 0 30px;
  padding: 15px;
  border-radius: 5px;
  max-width: 210px;
  font-size: 16px;
}
@media screen and (max-width: 1200px) {
  .cart_form__title {
    font-size: 32px;
  }
  .cart_form .agreement {
    margin-left: 0;
  }
  .cart_form .order_submit {
    margin-left: 0;
  }
  .cart_form {
    row-gap: 10px;
    grid-template-columns: repeat(3, 1fr) 230px;
  }
}
@media screen and (max-width: 991px) {
  .cart_form__title {
    font-size: 26px;
    margin-bottom: 15px;
    text-align: center;
  }
}
@media screen and (max-width: 800px) {
  .cart_form {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
  }
  .cart_form .cart_form__textarea {
    grid-column: 1/-1;
  }
  .cart_form .agreement {
    order: 1;
    grid-column: 1/3;
  }
  .cart_form .agreement a {
    display: block;
  }
  .cart_form .order_submit {
    order: 2;
    max-width: 230px;
    padding: 12px;
  }
}
@media screen and (max-width: 700px) {
  .cart_form input,
  .cart_form textarea {
    padding: 12px;
  }
}
@media screen and (max-width: 600px) {
  .cart_form {
    grid-template-columns: 1fr;
    row-gap: 10px;
  }
  .cart_form .cart_form__textarea {
    grid-column: initial;
  }
  .cart_form textarea {
    min-height: 100px;
  }
  .cart_form .agreement {
    grid-column: initial;
  }
  .cart_form .order_submit {
    width: 100%;
    margin: 0 auto;
  }
}
.cart_form__error {
  margin: 0;
  padding: 5px 0 0;
  color: red;
  font-size: 11px;
  line-height: 1.1;
}
.header_cart {
  position: relative;
  display: inline-block;
  min-width: 48px;
  height: 40px;
  padding-left: 43px;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  font-size: 16px;
  line-height: 62px;
  color: #000000;
  background: url("/assets/images/header_cart.svg") left bottom / 30px no-repeat;
}
.header_cart .num {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  font-size: 15px;
  line-height: 26px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #7bb638;
  color: #fff;
  top: 0;
  left: 24px;
}
@media screen and (max-width: 768px) {
  .header_cart {
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 20px;
  }
}
@media screen and (max-width: 480px) {
  .header_cart {
    top: 10px;
    right: 10px;
    width: 80px;
    padding-left: 0;
    min-height: 48px;
    padding-top: 48px;
    text-align: center;
    line-height: 1;
    background: url(/assets/images/header_cart.svg) center 12px / 30px no-repeat;
  }
  .header_cart .num {
    right: 5px;
    left: auto;
  }
}
#question {
  max-width: 710px;
  padding: 0;
  background-color: #fff;
}
#question .modals-inner,
#result .modals-inner {
  width: 100%;
  padding: 30px 45px 45px;
  border-bottom: 20px solid #7bb638;
  background: #fff;
}
@media (max-width: 600px) {
  #question .modals-inner,
  #result .modals-inner {
    padding: 20px 15px 25px;
  }
}
#question .modals-inner-capt,
#result .modals-inner-capt {
  margin: 0;
  padding: 15px 0;
  font-size: 30px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
#questionform {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 7px;
}
@media (max-width: 470px) {
  #questionform {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
  }
}
#questionform input[type="text"],
#questionform input[type="text"],
#questionform textarea {
  margin-bottom: 0;
  padding: 15px 18px;
  font-size: 14px;
}
#questionform textarea {
  height: 85px;
  min-height: 0;
}
#questionform .all {
  grid-column: 1/-1;
  margin-bottom: 10px;
}
#questionform .captcha_row {
  grid-column: 1/-1;
  margin-bottom: 15px;
}
#questionform .policy-form {
  margin-right: -20px;
}
#questionform .policy-form span::before {
  display: none;
}
#questionform .policy-form span {
  padding-left: 18px;
  font-size: 12px;
}
#questionform [type="submit"] {
  align-self: center;
  min-width: 170px;
  margin-left: 20px;
  margin-right: auto;
  padding: 12px 20px;
}
@media (max-width: 470px) {
  #questionform .policy-form {
    margin-right: 0;
  }
  #questionform [type="submit"] {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}
.question-link {
  position: fixed;
  right: 20px;
  bottom: 17%;
  width: 70px;
  height: 70px;
  padding: 0;
  background: none;
  border: none;
  text-align: center;
  border-radius: 35px;
  z-index: 10;
}
.question-link::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: #fff;
}
.question-link-icon {
  position: relative;
  z-index: 2;
  display: block;
  width: 70px;
  height: 70px;
  background-image: url("/assets/images/question-link.svg");
  background-size: 80px;
  background-position: center;
  filter: invert(74%) sepia(10%) saturate(3194%) hue-rotate(354deg)
    brightness(103%) contrast(92%);
}
.question-link-text {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 170px;
  padding: 17px 70px 17px 15px;
  color: #544230;
  font-size: 16px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
  text-align: center;
  line-height: 1.1;
  background-color: rgba(245, 182, 47, 0.3);
  border-radius: 35px;
}
.question-link:hover .question-link-icon {
  filter: invert(63%) sepia(14%) saturate(5055%) hue-rotate(8deg)
    brightness(103%) contrast(102%);
}
.question-link:hover .question-link-text {
  display: block;
}

/* icon basic styles !!!YOU NEED THEM  */
.menu-icon {
  height: 25px;
  cursor: pointer;
  width: 40px;
  position: relative;
  transform: scale(0.8);
  overflow: hidden;
}
.menu-icon:before,
.menu-icon:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  content: "";
  display: block;
  width: 4px;
  height: 28px;
  border-radius: 0;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background-color: #101010;
}
.menu-icon:before {
  position: absolute;
  top: 0;
  left: 18px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.menu-icon:after {
  position: absolute;
  top: 0;
  right: 18px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu-icon .bar {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  width: 100%;
  height: 5px;
  background-color: #101010;
  border-radius: 0;
}
.menu-icon .bar-1 {
  position: absolute;
  top: 0;
  left: 0;
}
.menu-icon .bar-2 {
  position: absolute;
  top: 10px;
  left: 0;
}
.menu-icon .bar-3 {
  position: absolute;
  top: 20px;
  left: 0;
}
.menu-icon.opened .bar {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.menu-icon.opened:before,
.menu-icon.opened:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.menu-icon-3:before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.menu-icon-3:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.menu-icon-3:before,
.menu-icon-3:after {
  top: -2px;
}

.menu-icon-3.opened:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-icon-3.opened:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-icon-3.opened:before,
.menu-icon-3.opened:after {
  top: 0px;
}
.menu-icon-3.opened .bar {
  opacity: 0;
}
.menu-icon-3.opened .bar-1 {
  top: 10px;
}
.menu-icon-3.opened .bar-3 {
  top: 10px;
}




.smesiteli-text {
  padding: 40px 0 0;
}
.smesiteli-text__intro {
  padding-bottom: 50px;
}
.smesiteli-text__bold {
  font-size: 16px;
  line-height: 30px;
  font-family: "Montserrat-Bold", Verdana, Helvetica;
}
.smesteli-text__descr {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 30px;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
}

.smesiteli-text__warning {
  padding: 60px 80px;
  background-color: #EFEFEF;
  overflow: hidden;
}
.smesiteli-text__header {
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
  padding: 20px 0;
  font-size: 30px;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  line-height: 35px;
  color: #fff;
}
.smesiteli-text__warning-title {
  position: relative;
}

.smesiteli-text__header::before {
  position: absolute;
  content: '';
  background-color: #7BB637;
  left: -80px;
  right: -80px;
  top: 0;
  bottom: 0;
}

.smesiteli-text__select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 60px;
  padding: 50px 0;
}
.smesiteli-text__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 25px;
     -moz-column-gap: 25px;
          column-gap: 25px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

.smesiteli-text__col-image,
.smesiteli-text__col-text {
  width: calc(50% - 12px);
}
.smesiteli-text__col-image {
  position: relative;
  padding: 45px 40px 45px 0;
}
.smesiteli-text__col-image::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  width: 73%;
  background-color: #F4B62F;
}
.smesiteli-text__image {
  position: relative;
}
.smesiteli-text__col-text {
  padding: 40px 5px 30px 25px;
}
.smesiteli-text__header-room {
  font-size: 35px;
  line-height: 40px;
  font-family: "Montserrat-Medium", Verdana, Helvetica;
  color: #282828;
  margin-top: 0;
  margin-bottom: 40px;
}

@media(max-width: 992px) {
  .smesiteli-text__warning {
    padding: 30px 40px;
  }
  .smesiteli-text__col-image {
    padding: 35px 30px 35px 0;
  }
  .smesiteli-text__col-text {
    padding: 20px 5px 20px 20px;
  }
  .smesiteli-text__header-room {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 25px;
  }
}

@media(max-width: 768px) {
  .smesiteli-text__intro {
    padding-bottom: 20px;
  }
  .smesiteli-text__warning {
    padding: 20px 20px;
  }
  .smesiteli-text__header {
    font-size: 26px;
    line-height: 32px;
    padding: 10px 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .smesiteli-text__select {
    padding: 30px 0;
    row-gap: 25px;
  }
  .smesiteli-text__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .smesiteli-text__kitchen {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .smesiteli-text__col-image,
  .smesiteli-text__col-text {
    width: 100%;
  }
  .smesiteli-text__col-text {
    padding: 45px 0 0;
  }
  .smesiteli-text__header-room {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }
  .smesiteli-text__col-image {
    padding: 20px 25px 20px 0;
  }
}

/* .catalog-block li.active > a {
  background-color: transparent;
  color: #f59a2f;
} */
.promo_popup {
  padding-left: 10px;
  padding-right: 10px;
}
.promo_popup img {
  display: block;
  border-radius: 20px;
}
.promo_popup .modal__close {
  background-color: #fff;
  width: 36px;
  height: 36px;
  top: 24px;
  right: 42px;
  background: transparent;
  border: 3px solid #5713b4;
  border-radius: 3px;
  padding: 2px;
}
.promo_popup .modal__close svg {
  color: #5713b4;
}
.promo_popup .modal__close svg path {
  stroke-width: 2px!important;
}
@media(max-width: 768px) {
  .promo_popup .modal__close {
    width: 32px;
    height: 32px;
    top: auto;
    right: 10px;
    bottom: 10px;
    border: 2px solid #5713b4;
    margin: 0;
  }
  .promo_popup img {
    border-radius: 0px;
  }
}
.item-sale {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(244, 218, 2, 0.9);
  font-size: 9px;
  text-align: center;
  padding: 5px;
  line-height: 1.2em;
}
.item-sale span,
.item-sale b {
  display: block;
}
.item-sale b {
  font-size: 8px;
}
.item-sale .item-sale_num {
  font-size: 20px;
  line-height: 1;
}
.item-sale,
.catalog-item .item-sale {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media(max-width: 600px) {
  .item-sale,
  .catalog-item .item-sale {
    top: 2px;
    right: 2px;
  }
}
.gdpr {
  position: fixed;
  top: auto;
  bottom: 0;
  height: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 3px 38px 0px rgba(99, 99, 99, 0.38);
  padding: 15px 0;
  font-family: "Montserrat-Medium";
  z-index: 1000;
}
.gdpr .container {
  display: grid;
  grid-template-columns: 1fr 244px;
  grid-column-gap: 80px;
  align-items: center;
  max-width: 1560px;
}
.container:before, .container:after {
  display: none;
}
.gdpr p,
.gdpr small {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
}
.gdpr .container > small {
  display: none;
}
.btn_close {
  grid-row: 1/3;
  grid-column: 2/3;
  display: block;
  margin: 0;
  padding: 20px;
  font-size: 14px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: #fff;
  cursor: pointer;
}
.btn_close:hover {
  color: #000;
}
@media screen and (max-width: 991px) {
  .gdpr .container {
    grid-template-columns: 1fr 200px;
    grid-column-gap: 40px;
  }
}
@media screen and (max-width: 680px) {
  .gdpr {
    padding: 20px 15px 15px;
  }
  .gdpr .container {
    grid-template-columns: 1fr 175px;
    grid-column-gap: 35px;
  }
  .gdpr p {
    grid-column: 1/-1;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .gdpr p small {
    display: none;
  }
  .gdpr .container > small {
    display: block;
    font-size: 12px;
    font-family: "Montserrat-Regular";
  }
  .btn_close {
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 13px;
  }
}
@media screen and (max-width: 480px) {
  .gdpr {
    padding: 20px 9px 15px;
  }
}
@media screen and (max-width: 414px) {
  .gdpr {
    padding: 15px 0 10px;
  }
}
#gdpr_form {
  display: none;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: rgba(#1e1e1e, 0.87);
  transition-timing-function: cubic-bezier(.22,.61,.36,1);
  text-align: center;
  overflow: initial;
  z-index: 99992;
}
.modal__inner {
  position: relative;
  display: inline-block;
  overflow: auto;
}
.modal__close {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: currentColor;
  cursor: pointer;
  opacity: .8;
  width: 45px;
  height: 45px;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 401;

  &:hover {
    opacity: 1;
    color: currentColor;
  }
  svg {
      display: block;
      height: 100%;
      overflow: visible;
      position: relative;
      width: 100%;

      path {
        fill: currentColor;
        stroke-width: 0;
      }
  }
}
.cart__success {
  p {
    font-size: 16px;
  }
  p:first-child {
    margin: 25px 0 0;
    color: #7bb638;
    font-size: 25px;
    font-family: "Montserrat-Bold", Verdana, Helvetica;
  }
}
.anim {
  &.enter {
    opacity: 0.01;
  }
  &.enter-active {
    opacity: 0.01;
  }
  &.enter-done {
    opacity:1; 
    transition: opacity 100ms linear;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0.01;
    transition: opacity 100ms linear;
  }
}
.swal2-container {
  z-index: 100000;
}
.swal2-popup {
  font-size: 15px;

  .swal2-title {
    font-size: 26px;
    font-family: "Montserrat-Bold", Verdana, Helvetica;
  }
  .swal2-confirm {
    background-color: #7bb638;
    color: #fff;
    font-size: 16px;
  }
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.captcha_row {
	display: grid;
	grid-template-columns: auto 1fr;;
	column-gap: 10px;
}
.captcha_row span {
	flex-shrink: 0;
}
.captcha_row img {
	display: block;
}
.captcha_row input {
	display: block;
	width: 100%;
	margin: 0;
}
.captcha_row .error-el {
  grid-column: 1/-1;
}
small .price-currency--simbol {
  font-size: 140%;
}

.price-val small {
  /* font-weight: 600; */
  font-size: 18px;
  /* font-family: 'Montserrat-Regular'; */
}

.product_warn_descr {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 20px 10px 20px 70px;
  background-color: #DEEDCD;
  font-size: 12px;
  line-height: 1.5;
  font-family: "Montserrat-Regular", Verdana, Helvetica;
  color: #151515;
}

.product_warn_descr::before {
  position: absolute;
  content: '';
  width: 34px;
  height: 29px;
  display: block;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('/assets/images/photo_ico.png');
  background-repeat: no-repeat;
}

.good-item_noprice {
  position: relative;
  padding-bottom: 35px;
}
.good-item__no-price-link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #7bb638;
  font-size: 14px;
  font-family: "Montserrat-Bold";
}
.good-item_price {
  padding-bottom: 35px;
}

.footer__descr-mobil {
  display: none;
}

.footer__row {
  display: grid;
  grid-template-columns: 165px 120px 170px 1fr;
  column-gap: 6%;
  justify-content: space-between;
}

.footer__col--2,
.footer__col--3,
.footer__col--4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 32px;
}

.footer__col--1 {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
}
.header-contacts-secr {
  line-height: 1.2;
  font-size: 13px;
}

@media screen and (max-width: 992px) {
  .footer__col--2 {
    display: none;
  }
  .header-contacts-text-desktop {
    display: none;
  }
  .footer__row {
    grid-template-columns: 150px 150px 1fr;
    grid-column: 40px;
  }
  .footer__descr-mobil {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
  }
  footer .creative-descr {
    display: none;
  }
  footer .copyrigt-block {
    margin-top: 0;
  }
  footer .copyrigt-block .copyright {
    margin-top: 5px;
  }

  .footer__col--1 {
    align-items: center;
    row-gap: 15px;
    justify-content: space-between;
  }
  .footer__col--3 {
    row-gap: 10px;
    min-width: 120px;
  }

  .header-contacts-secr {
    padding-left: 15px;
    border-left: #000 1px solid;
  }
}
@media screen and (max-width: 767px) {
  footer .logo,
  footer .header-contacts-item {
    margin-bottom: 0;
  }
  .header-contacts-secr {
    text-align: left;
  }
  .price-val small {
    font-size: 14px;
  }
}

@media screen and (max-width: 567px) {
  .footer__row {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
  }
  .good-item__no-price-link {
    font-size: 12px;
  }
}
@media screen and (max-width: 460px) {
  .good-item_price {
    padding-bottom: 0;
  }
}
.billet-block {
  width: 100%;
  max-width: 1920px;
  margin: 20px auto 40px;
  
  & a, & img {
    display: block;
  }
  @media screen and (max-width: 460px) {
    margin: 15px auto 35px;
  }
}
.site-creator {
  padding: 20px 0;
  background-color: #555555;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
}
.site-creator_link {
  display: inline-block;
  color: inherit;
  text-decoration: underline;
  font-weight: bold;
}
.site-creator_block {
  display: block;
}
@media (max-width: 600px) {
  .site-creator {
    font-size: 13px;
  }
}

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../fonts/Montserrat-Bold/Montserrat-Bold.eot");
	src: url("../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-Bold/Montserrat-Bold.woff") format("woff"),
        url("../fonts/Montserrat-Bold/Montserrat-Bold.woff2") format("woff2"),
        url("../fonts/Montserrat-Bold/Montserrat-Bold.ttf") format("truetype"),
        url("../fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
	font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: "Montserrat-ExtraBold";
	src: url("../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.eot");
	src: url("../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff") format("woff"),
        url("../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff2") format("woff2"),
        url("../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.ttf") format("truetype"),
        url("../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
	font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: "Montserrat-Regular";
	src: url("../fonts/Montserrat-Regular/Montserrat-Regular.eot");
	src: url("../fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-Regular/Montserrat-Regular.woff") format("woff"),
        url("../fonts/Montserrat-Regular/Montserrat-Regular.woff2") format("woff2"),
        url("../fonts/Montserrat-Regular/Montserrat-Regular.ttf") format("truetype"),
        url("../fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
	font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: "Montserrat-Medium";
	src: url("../fonts/Montserrat-Medium/Montserrat-Medium.eot");
	src: url("../fonts/Montserrat-Medium/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-Medium/Montserrat-Medium.woff") format("woff"),
        url("../fonts/Montserrat-Medium/Montserrat-Medium.woff2") format("woff2"),
        url("../fonts/Montserrat-Medium/Montserrat-Medium.ttf") format("truetype"),
        url("../fonts/Montserrat-Medium/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
	font-weight: normal;
    font-style: normal;
    font-display: swap;
}